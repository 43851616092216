<template>
  <div>
    <!-- <div class="d-block d-sm-none p-3 pt-0 mt-0">
      <b-button variant="secondary" block @click="$emit('closeModal')"
        ><i class="fa fa-arrow-left"></i> Voltar</b-button
      >
    </div> -->

    <div class="row">
      <b-button
        v-b-toggle.collapse-1
        variant="primary"
        v-if=" mesa && mesa.id > 0 && grupoSelecionado.Grupo == 'PIZZA'"
        class="d-block d-sm-none"
        block
      >
        Tamanho {{ preco_tipo ? preco_tipo.tipo : "" }}
      </b-button>
      <div
        class="col-12 col-sm-8 pr-sm-1 opTamanho"
        v-if="
          $store.state.tipos_preco &&
          $store.state.tipos_preco.length > 0 &&
          grupoSelecionado.Grupo == 'PIZZA'
        "
        :class="{ 'col-sm-12': mesa }"
      >
        <b-collapse
          id="collapse-1"
          class="mt-2"
          :visible="tamanhoPizzaCollapse"
          :style="mesa ? 'margin:0 auto; width:100%' : ''"
        >
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="btn-radios-2"
              v-model="preco_tipo"
              :options="
                $store.state.tipos_preco.map((it) => {
                  return { value: it, text: it.tipo };
                })
              "
              :aria-describedby="ariaDescribedby"
              button-variant="outline-secondary"
              :size="isMobile ? 'lg' : 'sm'"
              name="radio-btn-outline"
              buttons
              fill
              :class="{ 'w-100': mesa }"
              :stacked="$store.state.isMobile"
            />
          </b-form-group>
        </b-collapse>
      </div>

      
    </div>

    <b-button
      v-b-toggle.collapse-2
      variant="primary"
      v-if="mesa && mesa.id > 0"
      class="d-block d-sm-none mt-1"
      block
    >
      Opções
    </b-button>
    <div
      class="col-12 col-sm-3 pl-sm-1"
      :class="{ 'w-100 col-sm-12 pr-sm-3 pl-sm-3': mesa }"
    ></div>

    <b-modal
      id="modal-add-item-mobile"
      hide-footer
      size="lg"
      content-class="prodModal"
      no-fade
      no-close-on-backdrop
      @hidden="$bvModal.hide('modal-add-item-mobile')"
    >
      <cardapio-produto-lista
        :add="add"
        
        :grupoSelecionado="grupoSelecionado"
        :produtosShow="produtosShow"
        :mesa="mesa"
        :isMobile="isMobile"
        :subgrupos="subgrupos"
        :opc_selecionada="opc_selecionada"
        :empresaSelecionada="empresaSelecionada"
        :preco_tipo="preco_tipo"
      ></cardapio-produto-lista>
    </b-modal>
    
    <b-collapse
      id="collapse-2"
      class="mt-2"
      :visible="saborCollapse"
      v-if="!isMobile || (grupoSelecionado.Grupo == 'PIZZA' && (!mesa || mesa.id <= 0)) || grupoSelecionado.Grupo != 'PIZZA'"
    >
      <cardapio-produto-lista
        :add="add"
        :grupoSelecionado="grupoSelecionado"
        :produtosShow="produtosShow"
        :mesa="mesa"
        :isMobile="isMobile"
        :subgrupos="subgrupos"        
        :opc_selecionada="opc_selecionada"
        :empresaSelecionada="empresaSelecionada"
        :preco_tipo="preco_tipo"
      ></cardapio-produto-lista>
    </b-collapse>
    <b-modal
      id="modal-addds"
      title="Adicionais"
      hide-footer
      v-if="itemComAdicional"
    >
      <b-table
        :items="itemComAdicional.ingredientes"
        small
        style="font-size: 10px"
        :fields="[
          { key: 'ingrediente', label: 'Opção' },
          { key: 'preco_venda', label: 'Valor' },
          { key: 'opc', label: '' },
        ]"
      >
        <template #cell(preco_venda)="row">
          <span v-if="!row.item.preco_venda || row.item.preco_venda <= 0">
            Grátis
          </span>
          <span v-else>
            {{ row.item.preco_venda | currency }}
          </span>
        </template>
        <template #cell(opc)="row">
          <quantidade :Model="row.item" :showRemover="false" />
        </template>
      </b-table>
      <hr />
      <div class="row">
        <div class="col-6 text-center">
          <h3>
            {{
              (itemComAdicional.preco_venda +
                itemComAdicional.ingredientes.reduce((ret, vl) => {
                  ret += (vl.preco_venda || 0) * (vl.qtd || 0);
                  return ret;
                }, 0))
                | currencyMask
            }}
          </h3>
        </div>
        <div class="col-6 text-center">
          <b-btn variant="success" block size="lg" @click="addAdicional"
            >ADICIONAR</b-btn
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Quantidade from "../common/Quantidade.vue";
import CardapioProdutoLista from "./CardapioProdutoLista.vue";
// import Cardapio from "@/components/pedido/Cardapio";
export default {
  props: {
    grupoSelecionado: Object,
    produtos: Array,
    adicionar: Function,
    opc_selecionada: Array,
    selectTipoPreco: Function,
    refBorda: Object,
    preco_tipo_inicial: Object,
    mesa: Object,
    empresaSelecionada: Number,
  },
  components: {
    // Cardapio,
    Quantidade,
    CardapioProdutoLista,
  },
  data() {
    return {
      hasAdicional: false,
      preco_tipo: null,
      // filtrar: "",
      produtosShow: [],
      tamanhoPizzaCollapse: true,
      saborCollapse:
        this.grupoSelecionado.Grupo != "PIZZA" ||
        !this.$store.state.isMobile ||
        !this.$props.mesa,
      adicionais: [],
      itemComAdicional: null,
      subgrupos: [],
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },

  mounted() {
  
    // console.log("START PRODS", this.produtos);
    this.subgrupos = this.produtos
      .reduce((ret, vl) => {
        let has = ret.find((x) => x.Tipo == vl.Grupo);
        if (!has) {
          ret.push({
            Tipo: vl.Grupo,
            produtos: [vl],
          });
        } else {
          has.produtos.push(vl);
        }
        return ret;
      }, [])
      .sort((a, b) => {
        return a.Tipo && b.Tipo && a.Tipo > b.Tipo ? -1 : 1;
      })
      .sort((a, b) => {
        return a.Tipo && a.Tipo.toUpperCase().indexOf("DOCE") >= 0 ||
          b.Tipo && b.Tipo.toUpperCase().indexOf("DOCE") >= 0
          ? 1
          : 0;
      });

    this.produtosShow = this.subgrupos;

    // console.log("ujbs", this.subgrupos);

    if (this.grupoSelecionado.Grupo == "PIZZA") {
      // let tp = this.grupoSelecionado.tipos_preco.filter(
      //   (x) => x.tipo.indexOf("GRANDE") >= 0
      // );
      // if (tp && tp.length > 0) {
      //   this.preco_tipo = tp[0];
      // }
    }
    if (this.$props.preco_tipo_inicial) {
      this.preco_tipo = this.$store.state.tipos_preco.find(
        (x) => x.cod_tipo == this.$props.preco_tipo_inicial.cod_tipo
      );
    }
  },
  watch: {
    "$props.preco_tipo_inicial": function () {
      if (this.$props.preco_tipo_inicial) {
        this.preco_tipo = this.$store.state.tipos_preco.find(
          (x) => x.cod_tipo == this.$props.preco_tipo_inicial.cod_tipo
        );
      }
    },
    preco_tipo: function () {
      if (this.selectTipoPreco) {
        this.selectTipoPreco(this.preco_tipo);
      }
      if (
        this.mesa &&
        this.mesa.id > 0 &&
        this.tamanhoPizzaCollapse &&
        this.$store.state.isMobile
      ) {
        this.tamanhoPizzaCollapse = false;
        this.saborCollapse = true;
      }
    },
    produtos: function () {
      // console.log("pros", this.produtos);
      this.produtosShow = this.produtos;
    },
  },

  methods: {
    openChooseSabor(sabor) {
      sabor;
      this.$bvModal.show("modal-add-item-mobile")
    },
    addAdicional() {
      this.itemComAdicional.preco_venda +=
        this.itemComAdicional.ingredientes.reduce((ret, vl) => {
          ret += (vl.preco_venda || 0) * (vl.qtd || 0);
          return ret;
        }, 0);
      console.log(this.itemComAdicional);
      this.adicionar(this.itemComAdicional);
      this.$bvModal.hide("modal-addds");
      this.$emit("closeAndSave");
    },

    async add(item) {
      console.log("adding", item);
      this.$bvModal.hide("modal-add-item-mobile")

      if (this.verificarStatus(item, item.nome)) {
        this.adicionais = [];
        this.itemComAdicional = null;
        if (
          this.grupoSelecionado.Grupo !== "PIZZA" &&
          item.aceita_ingrediente == 2 &&
          item.ingredientes.length > 0 &&
          this.opc_selecionada.filter((x) => x.cod_produto == item.cod_produto)
            .length <= 0
        ) {
          this.itemComAdicional = Object.assign({}, { ...item });
          // this.itemComAdicional = item;
          console.log(321, this.itemComAdicional);
          await this.$nextTick();
          this.itemComAdicional.ingredientes =
            this.itemComAdicional.ingredientes.map((it) => {
              return {
                ...it,
                qtd: 0,
              };
            });

          // console.log(123, this.itemComAdicional);

          this.$bvModal.show("modal-addds");
        } else {
          this.adicionar(item);
        }
        if (item.produtos && item.produtos.length > 0) {
          for (let prod of item.produtos) {
            await this.add({ ...prod.produto, preco_venda: 0 });
          }
          //return;
        }
      }

      this.setFocus();
    },
    verificarStatus(item, nome = "") {
      const { status } = item;

      // console.log(status);
      if (status) {
        let inativos = status.filter(
          (stts) => stts.cod_empresa == this.empresaSelecionada && !stts.status
        );
        if (inativos.length > 0) {
          this.$swal({
            showConfirmButton: true,

            title: `Atenção`,
            html: `${
              nome ? nome : item.nome ? item.nome : "produto"
            } indisponível nos locais: <b>${inativos
              .map((ina) => ina.empresa)
              .toString()}</b>`,
            // html: `<b>${inativos.map(ina => ina.empresa).toString()}</b>`,
            icon: "warning",
          });
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    setFocus() {
      // if (!this.$store.state.isMobile) {
      //   this.$refs.txtFiltrar.focus();
      //   this.$refs.txtFiltrar.select();
      // }
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/assets/scss/cardapio.scss"
</style>
