<template>
  <div>
    <div class="col-12 col-sm-12 mt-3" :class="{}">
      <div class="d-sm-flex justify-content-between w-100">
        <b-input
          :style="'max-width:300px;'"
          type="text"
          class="form-control"
          placeholder="buscar..."
          v-model="filtrar"
          ref="txtFiltrar"
          @keypress="EnterProd"
          @focus="$refs.txtFiltrar.select()"
        />

        <!-- <b-input
          v-if="mesa"
          :style="!mesa ? 'max-width:300px; width:100%' : ''"
          type="text"
          class="form-control"
          placeholder="buscar..."
          v-model="filtrar"
          ref="txtFiltrar"
          id="txtBuscaPizza"
          name="buscaPizza"
          @keypress="EnterProd"
          @focus="$refs.txtFiltrar.select()"
          :class="{ 'w-100': mesa }"
        /> -->
        <div
          class="px-sm-4 py-2 bg-light d-flex scrollPersonalizadoH "
          style="flex: 1; overflow: hidden; overflow-x: auto"
          v-if="subgrupos && subgrupos.length > 0"
        >
          <b-button
            v-for="sg in subgrupos"
            :key="`key_${sg.Tipo}_${Math.random()}`"
            class="btn-sm mr-2"
            @click="selectGroup(sg)"
            :title="sg.Tipo"
            :variant="
              selectedGroups.indexOf(sg) >= 0 ? 'info' : 'outline-secondary'
            "
            style="font-size: 9px"
          >
            {{ sg.Tipo }}
          </b-button>
        </div>
      </div>
    </div>
    <div
      class="produtos"
      :class="{ produtosListaMaior: this.grupoSelecionado.Grupo != 'PIZZA' }"
      :style="
        !isMobile
          ? `max-height:${
              grupoSelecionado.Grupo == 'PIZZA'
                ? isMobile
                  ? '60vh'
                  : '32vh'
                : '550px; height: auto'
            }`
          : ``
      "
    >
      <div
        class="grp"
        v-for="grp in produtosShow"
        :key="`key_Grp_${grp.Tipo}_${Math.random()}`"
      >
        <div class="grpTitulo">{{ grp.Tipo }}</div>
        <CardapioItem
          :empresaSelecionada="empresaSelecionada"
          v-for="opc in grp.produtos"
          :key="opc.cod_produto"
          :item="opc"
          :preco_tipo="preco_tipo"
          :add="add"
          @click.native="add(opc)"
          :selecionado="
            opc_selecionada.filter((x) => x.cod_produto == opc.cod_produto)
              .length > 0
          "
          :ref="`prod_${opc.cod_produto}`"
          :withPrice="grupoSelecionado.Grupo != 'PIZZA'"
          :mesa="mesa"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CardapioItem from "./CardapioItem.vue";

export default {
  components: {
    CardapioItem,
  },
  props: {
    produtoShow: Object,
    produtosShow: Array,
    subgrupos: Array,
    mesa: Object,
    grupoSelecionado: Object,
    // EnterProd: Function,
    add: Function,
    isMobile: Boolean,
    // filtrar: String,
    opc_selecionada: {
      type: [Number, Array],
      default: 0,
    },
    empresaSelecionada: Number,
    preco_tipo: Object,
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.setFocus();
      }, 300);
    });
  },
  data() {
    return {
      filtrar: "",
      selectedGroups: [],
    };
  },
  watch: {
    filtrar: function () {
      this.applyFilter();
    },
    selectedGroups: function () {
      this.applyFilter();
    },
  },
  computed: {},
  methods: {
    applyFilter() {
      if (
        this.filtrar &&
        this.filtrar.length > 0 &&
        this.grupoSelecionado &&
        this.grupoSelecionado.produtos &&
        this.grupoSelecionado.produtos.length > 0
      ) {
        console.log("iniciando filtro");
        this.produtosShow = JSON.parse(JSON.stringify(this.subgrupos))
          .sort((a, b) => {
            return a.Tipo.toUpperCase().indexOf("DOCE") >= 0 ||
              b.Tipo.toUpperCase().indexOf("DOCE") >= 0
              ? 1
              : 0;
          })
          .filter(
            (x) =>
              x.produtos.some(
                (u) =>
                  u.cod_produto
                    .toString()
                    .toUpperCase()
                    .indexOf(this.filtrar.toUpperCase()) >= 0
              ) ||
              x.produtos.some(
                (u) =>
                  u.nome
                    .toString()
                    .toUpperCase()
                    .indexOf(this.filtrar.toUpperCase()) >= 0
              )
          );
        console.log("fiultrado", this.produtosShow);
        for (let ps of this.produtosShow) {
          ps.produtos = ps.produtos.filter(
            (x) =>
              x.cod_produto.toString() == this.filtrar ||
              x.nome
                .toString()
                .toUpperCase()
                .indexOf(this.filtrar.toUpperCase()) >= 0
          );
        }
      } else {
        this.produtosShow = this.subgrupos.sort((a, b) => {
          return a.Tipo.toUpperCase().indexOf("DOCE") >= 0 ||
            b.Tipo.toUpperCase().indexOf("DOCE") >= 0
            ? 1
            : 0;
        });
      }

      setTimeout(() => {
        this.$nextTick(() => {
          if (this.selectedGroups && this.selectedGroups.length > 0) {
            console.log(
              "aplicando filtro de grupos",
              this.selectedGroups,
              this.produtosShow
            );
            this.produtosShow = this.produtosShow.filter((x) =>
              this.selectedGroups.some((y) => x.Tipo == y.Tipo)
            );
          }
        });
      }, 300);
    },
    selectGroup(gp) {
      if (gp) {
        if (this.selectedGroups.indexOf(gp) >= 0) {
          this.selectedGroups.splice(this.selectedGroups.indexOf(gp), 1);
        } else {
          this.selectedGroups.push(gp);
        }
      }
    },
    setFocus() {
      if (!this.$store.state.isMobile) {
        this.$refs.txtFiltrar.focus();
        this.$refs.txtFiltrar.select();
      }
    },
    EnterProd(evt) {
      if (evt && evt.key == "Enter" && this.produtosShow.length > 0) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.add(this.produtosShow[0].produtos[0]);

            this.setFocus();
          }, 100);
        });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/assets/scss/cardapio.scss"
</style>
