<template>
  <div>
    <div class="row" v-if="!obj.id">
      <div class="col-12">
        <b-form-group label="Nome do cliente">
          <b-input v-model="obj.devedor_nome" autocomplete="off" />
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group label="Valor">
          <b-input
            v-model="obj.valor"
            class="text-center"
            v-money="moneyMask"
            :readonly="!mesa"
          />
        </b-form-group>
      </div>
      <div class="col-6">
        <b-btn
          variant="success"
          block
          class="mt-4"
          :disabled="gerandoQRCode"
          @click="gerar"
        >
          <span v-if="!gerandoQRCode">
            <i class="fa fa-qrcode" /> Gerar QR Code
          </span>
          <span v-if="gerandoQRCode">
            <b-spinner small size="small" /> gerando...
          </span>
        </b-btn>
      </div>
      <div class="col-12" v-if="user.nivel == 0">
        <hr />

        <b-form-group label="Localizar por Identificador">
          <b-input
            v-model="findID"
            autocomplete="off"
            @keypress.enter.prevent="pixUpdate"
          />
        </b-form-group>
      </div>
      <div class="col-12" v-if="user.nivel == 0">
        <b-btn
          variant="primary"
          size="sm"
          @click="pixUpdate"
          :disabled="buscandoQRcode"
        >
          <span v-if="!buscandoQRcode"> <b-icon-search /> Buscar </span>
          <span v-if="buscandoQRcode">
            <b-spinner small size="small" /> localizando...
          </span>
        </b-btn>
        <b-btn variant="secondary" size="sm" @click="openListPix"
          ><b-icon-list /> Listagem</b-btn
        >
      </div>
    </div>

    <!-- ----------------------- PIX ENCONTRADO -------------------------------->
    <div class="row" v-if="obj.id && obj.id > 0">
      <div class="col-12">
        <label>Identificador</label><br />
        <small
          >{{ obj.id }} - <small>{{ obj.txId }}</small></small
        >
        <hr />
        <label>Código COPIA E COLA</label><br />
        <div class="w-100" style="word-break: break-all">{{ obj.pixCode }}</div>
        <hr />
      </div>
      <div class="col-12 text-center">
        <img :src="obj.pixQrCode" class="m-auto" />
        <br />
        <small v-if="timeExp.indexOf('-') < 0">
          Tempo restante para expirar {{ timeExp }}
        </small>
        <small v-else class="text-danger"> QRCode expirado! </small>
        <hr />
        <b>STATUS: </b> {{ obj.status }}
        {{ obj.status == "ATIVA" ? " - Aguardando pagamento" : "" }}
        <br />
        <br />
        <span class="text-success" v-if="obj.status == 'PAGO'">
          <h3 class="text-center">
            <b>
              <b-icon-check-circle
                style="font-size: 90px; width: 50px; height: 50px"
              />
              <br />
              PAGAMENTO REALIZADO COM SUCESSO!</b
            >
          </h3>
        </span>

        <div
          v-if="
            obj.status == 'DEVOLVIDO' &&
            obj.devolucoes &&
            obj.devolucoes.length > 0
          "
        >
          <hr />
          <b>Identificador: </b> {{ obj.devolucoes[0].id }}<br />
          <b>Identificador Retorno: </b>
          {{ obj.devolucoes[0].rtrId }}<br />
          <b>Data Solicitação: </b>
          {{
            obj.devolucoes[0].horario.solicitacao
              | moment("DD/MM/YYYY HH:mm:ss")
          }}<br />
          <b>Data Devolução: </b>
          {{
            obj.devolucoes[0].horario.liquidacao
              | moment("DD/MM/YYYY HH:mm:ss")
          }}<br />
          <b>Valor </b>
          {{ obj.devolucoes[0].valor | currency }}<br />
          <b>Status </b> {{ obj.devolucoes[0].status }}<br />

          <hr />
        </div>
      </div>

      <div class="col-12">
        <hr />
        <b-btn
          variant="primary"
          size="sm"
          @click="pixUpdate"
          :disabled="buscandoQRcode"
          block
          v-if="!['PAGO', 'EXPIRADO', 'DEVOLVIDO'].includes(obj.status)"
        >
          <span v-if="!buscandoQRcode"> <b-icon-recycle /> Atualizar </span>
          <span v-if="buscandoQRcode">
            <b-spinner small size="small" /> localizando...
          </span>
        </b-btn>

        <b-btn
          block
          v-if="!['PAGO', 'EXPIRADO'].includes(obj.status)"
          variant="warning"
          type="button"
          @click.stop="openSendWpp"
          ><b-icon-whatsapp />
          <span v-if="obj.status == 'DEVOLVIDO'">
            Enviar comprovante de devolução por whatsapp.
          </span>
          <span v-else> Enviar por whatsapp </span>
        </b-btn>

        <b-btn
          variant="danger"
          v-if="obj.status == 'PAGO'"
          block
          @click="cancelarPIX"
        >
          <span v-if="!cancelandoPix">
            <b-icon-x-circle /> Cancelar/Devolver PIX
          </span>
          <span v-else>
            <b-spinner small size="sm" />
            cancelando...
          </span>
        </b-btn>

        <b-btn
          block
          variant="secondary"
          @click="reset"
          :disabled="buscandoQRcode"
          ><b-icon-back /> Voltar</b-btn
        >
      </div>
    </div>
    <b-modal id="modal-wpp" hide-footer title="Enviar cobrança para whatsapp">
      <b-form-group label="Número">
        <input
          class="form-control optional-custom-input"
          v-model="wppNumber"
          v-mask="['(##) ####-####', '(##) #####-####']"
          autocomplete="off"
          :id="`txtField${Math.random()}`"
          :name="`txtField${Math.random()}`"
          pattern="\d*"
        />
        <div class="text-center p-2">
          <hr />
          <b-alert :show="wppAlert.show" class="mt-2" :variant="wppAlert.type">
            {{ wppAlert.message }}
          </b-alert>
          <b-btn variant="success" @click="enviarWpp">
            <i class="fab fa-whatsapp"></i> Enviar</b-btn
          >
          <b-btn variant="secondary" @click="$bvModal.hide('modal-wpp')">
            <i class="fab fa-whatsapp"></i> Enviar</b-btn
          >
        </div>
      </b-form-group>
    </b-modal>
    <b-modal id="modal-list-pix" hide-footer size="lg">
      <RelatorioPix
        :selectable="true"
        :hideFiltros="true"
        @selected="selectPix"
      />
    </b-modal>
  </div>
</template>

<script>
import PixLib from "../../libs/PixLib";
import WppServ from "../../libs/WhatsAppService";
import moment from "moment";
import RelatorioPix from "./RelatorioPix.vue";
export default {
  components: { RelatorioPix },
  props: {
    valor: Number,
    clienteNome: String,
    mesa: {
      type: Boolean,
      default: false,
    },
    clienteTelefone: String,
    dadosIniciais: Object,
  },
  mounted() {
    if (this.dadosIniciais) {
      this.obj = this.dadosIniciais;
    }
  },
  data() {
    return {
      obj: {
        devedor_nome: "",
        valor: 0,
        observacao: "",
        id: null,
        txId: null,
        status: "PENDENTE",

        chave: null,
        companyId: null,
        corpoRequest: null,
        createdAt: null,
        devedor_cpf: null,
        devolucoes: null,
        expiracao: 900,
        externalRef: "",
        paymentAt: null,
        pixCode: null,
        pixEndToEndCode: null,
        pixQrCode: null,
        pixServiceId: null,
        processAt: "",
      },
      gerandoQRCode: false,
      buscandoQRcode: false,
      timerAtualizaPix: null,
      findID: "",
      timeExp: "",
      podeCancelarPix: true,
      cancelandoPix: false,
      wppNumber: "",
      telefonesDisponiveis: [],
      enviandoWpp: false,
      wppAlert: {
        show: false,
        type: "success",
        message: "",
      },
    };
  },
  watch: {
    "$props.clienteTelefone": function () {
      this.obj.wppNumber = this.$props.clienteTelefone;
    },
    "$props.clienteNome": function () {
      this.obj.devedor_nome = this.$props.clienteNome;
    },
    "$props.valor": function () {
      this.obj.valor = this.formatFloatToReal(this.$props.valor);
    },
    obj: {
      deep: true,
      handler() {
        if (this.timerAtualizaPix) {
          clearInterval(this.timerAtualizaPix);
        }
        this.timerAtualizaPix = null;

        if (this.obj && this.obj.status && this.obj.status === "ATIVA") {
          this.findID = this.obj.id;
          this.timerAtualizaPix = setTimeout(() => {
            this.pixUpdate();
          }, 2000);
        }

        if (
          this.obj.processAt &&
          this.obj.expiracao &&
          this.obj.status == "ATIVA"
        ) {
          let tempoMinutos = moment(this.obj.processAt)
            .add(this.obj.expiracao, "s")
            .diff(moment(), "minutes");
          let tempoSegundos = parseInt(
            moment(this.obj.processAt)
              .add(this.obj.expiracao, "s")
              .diff(moment(), "seconds") % 60
          );
          this.timeExp =
            (tempoMinutos < 10 ? "0" : "") +
            tempoMinutos +
            ":" +
            (tempoSegundos < 10 ? "0" : "") +
            tempoSegundos;
        } else if (this.obj.status == "EXPIRADO") {
          this.timeExp = "EXPIRADO";
        }
      },
    },
  },
  computed: {},
  methods: {
    selectPix(pix) {
      this.$bvModal.hide("modal-list-pix");

      this.findID = pix.id;
      this.obj.id = pix.id;
      this.pixUpdate();
    },
    openListPix() {
      this.$bvModal.show("modal-list-pix");
    },
    reset() {
      if (this.timerAtualizaPix) {
        clearInterval(this.timerAtualizaPix);
      }
      this.timerAtualizaPix = null;

      this.obj = {
        devedor_nome: this.$props.clienteNome,
        valor: this.formatFloatToReal(this.$props.valor),
        observacao: "",
        id: null,
        txId: null,
        status: "PENDENTE",
        chave: null,
        companyId: null,
        corpoRequest: null,
        createdAt: null,
        devedor_cpf: null,
        devolucoes: null,
        expiracao: 900,
        externalRef: "",
        paymentAt: null,
        pixCode: null,
        pixEndToEndCode: null,
        pixQrCode: null,
        pixServiceId: null,
        processAt: "",
      };
      this.$emit("concluido", null);
    },
    async gerar() {
      this.gerandoQRCode = true;
      try {
        let res = await PixLib.createPix(
          this.obj.devedor_nome
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),
          null,
          this.formatValorReal(this.obj.valor),
          "CIA DA PIZZA"
        );
        console.log("res", res);
        this.obj = Object.assign(this.obj, res);
      } catch (error) {
        console.log("ERRO AO GERAR QRCODE", error);
      }
      this.gerandoQRCode = false;
    },
    async pixUpdate() {
      if (!this.findID || this.findID == "") {
        this.$swal("Atenção", "Informe um ID para localizar!", "error");
        return;
      }
      this.buscandoQRcode = true;
      try {
        let res = await PixLib.consultarPix(this.findID);
        console.log("Result", res);
        if (res && res.success === false) {
          this.$swal(
            "PIX não localizado!",
            "O identificador digitado não foi localizado!",
            "error"
          );
          this.reset();
        } else if (res && res.venda != null) {
          this.$swal(
            "PIX já utilizado!",
            "O PIX localizado já está sendo utilizado no pedido nº " +
              (res.venda.senha || res.venda.id + " | " + res.venda.mesa) +
              "",
            "error"
          );
          this.reset();
        } else {
          this.obj = Object.assign(this.obj, res);
          if (res.status == "PAGO") {
            this.$emit("concluido", res);
          }
        }
      } catch (error) {
        console.log("Erro ao buscar", error);
      }
      this.buscandoQRcode = false;
    },
    async cancelarPIX() {
      if (this.obj && this.obj.id && this.obj.status === "PAGO") {
        this.$swal
          .fire({
            title: "Tem certeza?",
            text: "Você tem certeza que dejsea cancelar o PIX? O valor será devolvido para o cliente e não será possível reverter a situação!",
            showCancelButton: true,
            cancelButtonText: "Não. Cancelar!",
            confirmButtonText: "Sim. Devolver!",
            icon: "question",
          })
          .then(async (resp) => {
            if (resp.isConfirmed) {
              this.cancelandoPix = true;
              try {
                await PixLib.cancelarPix(this.obj.id, this.obj.valor);
                this.pixUpdate();
              } catch (error) {
                console.log("ERRO AO SOLIC. CANCELAR PIX", error);
              }
              this.cancelandoPix = false;
            }
          });
      }
    },
    openSendWpp() {
      this.$bvModal.show("modal-wpp");

      this.wppNumber = "16" + this.clienteTelefone;

      // this.$nextTick().then(() => {
      //   if (this.funcionario && this.funcionario.telefones) {
      //     this.wppNumber = this.funcionario.telefones[0].telefone;
      //     console.log("aa", this.wppNumber);

      //     this.telefonesDisponiveis = this.funcionario.telefones.map(
      //       (it) => it.telefone
      //     );

      //     console.log("aa", this.wppNumber);
      //   }
      // });
    },
    async enviarWpp() {
      this.wppAlert.show = true;
      if (this.wppNumber.trim() == "" || this.wppNumber.length < 14) {
        this.wppAlert.type = "danger";
        this.wppAlert.message = "Informe um número válido";
        return;
      }
      this.enviandoWpp = true;
      // try {

      if (this.obj.status == "ATIVA") {
        let res1 = await WppServ.enviarTextoSimples(
          this.wppNumber,
          "PIX gerado com sucesso!\nUtilize a câmera do celular para ler o QRCode ou 'Copie e Cole' o código de pagamento no seu aplicativo de banco."
        );
        let res2 = await WppServ.enviarImagem(
          this.wppNumber,
          this.obj.pixQrCode,
          ""
        );
        await WppServ.enviarTextoSimples(
          this.wppNumber,
          "CODIGO PARA PAGAMENTO ABAIXO, UTILIZE COPIAR E COLAR"
        );
        let res3 = await WppServ.enviarTextoSimples(
          this.wppNumber,
          this.obj.pixCode
        );
        console.log("ressss", res1, res2, res3);
      } else if (
        this.obj.status == "DEVOLVIDO" &&
        this.obj.devolucoes &&
        this.obj.devolucoes.length > 0
      ) {
        let text = `Solicitação de devolução de PIX\n*Identificador:*\n ${
          this.obj.devolucoes[0].id
        }\n*Identificador de Retorno:* \n ${
          this.obj.devolucoes[0].rtrId
        }\n\n*Data da Solicitação Devolução:* \n ${
          this.obj.devolucoes[0].horario.solicitacao
            ? moment(this.obj.devolucoes[0].horario.solicitacao).format(
                "DD/MM/YYYY HH:mm:ss"
              )
            : "não solicitado"
        }\n\n*Data da Devolução:*\n ${
          this.obj.devolucoes[0].horario.liquidacao
            ? moment(this.obj.devolucoes[0].horario.liquidacao).format(
                "DD/MM/YYYY HH:mm:ss"
              )
            : "não confirmado"
        }\n\n*Valor:*\n ${this.formatFloatToReal(
          this.obj.devolucoes[0].valor
        )}\n\n*Status:* \n ${this.obj.devolucoes[0].status}
        `;
        await WppServ.enviarTextoSimples(this.wppNumber, text);
      }

      this.wppAlert.type = "success";
      this.wppAlert.message = "Informações enviadas com sucesso!";
      this.$bvModal.hide("modal-wpp");

      // } catch (error) {
      // console.log("ERROR ON SEND WPP", error);
      // }
      this.enviandoWpp = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
