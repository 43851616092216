<template>
  <div>
    <PedidoAddItemNovo />
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        grow
        small
        variant="primary"
      ></b-spinner>
      carregando...
    </div>
    <div
      class="bg-danger text-white p-2 text-center"
      style="cursor: pointer"
      v-if="pedido && pedido.excluido && pedido.excluido == 1"
      @click="descancelar"
    >
      PEDIDO CANCELADO (clique para voltar para ativo)
    </div>

    <div
      class="p-0 p-sm-3"
      :class="{ container: !pedido, 'container-fluid': pedido }"
      :style="resumed ? 'min-height:600px' : ''"
    >
      <div class="row">
        <div
          class="col-12 col-lg-6"
          v-show="!mesa"
        >
          <div class="titulo">
            <div class="texto pl-2">
              <i class="fas fa-industry"></i> Empresa
            </div>
            <div class="combo">
              <empresa-combo
                v-show="!resumed"
                ref="empresaCombo"
                :selecionar="selecionarEmpresa"
                :selected="
                  pedido
                    ? pedido.cod_empresa
                    : empresa_selecionada
                    ? empresa_selecionada.cod_empresa
                    : null
                "
                class=""
              />
            </div>
          </div>
          <div class="titulo">
            <div class="texto pl-2">
              <i class="fas fa-cash-register"></i> Caixa
            </div>
            <div class="combo">
              <caixa-combo
                :CaixasLista="caixasDisponiveis"
                :caixa="cod_caixa"
                @ch="changeCaixa($event)"
                :ComTodos="false"
              />
            </div>
          </div>

          <div
            class="titulo"
            v-if="
              (horaAgora > 8 && !pedido) ||
              (pedido && !pedido.data) ||
              horaPedido > 8
            "
          >
            <div class="texto pl-2"><i class="fas fa-calendar"></i> Data</div>
            <div
              class="combo"
              style="max-width: 160px"
            >
              <b-input
                type="date"
                v-model="data"
              />
            </div>
          </div>

          <div
            class="titulo"
            v-if="
              pedido &&
              ($store.state.auth.user.tipo.indexOf('Admin') >= 0 ||
                $store.state.auth.user.tipo.indexOf('Gerente') >= 0)
            "
          >
            <div class="texto pl-2">
              <i class="fas fa-ban"></i> Problema no Pedido
              <b-btn
                variant="danger"
                @click="removerProblema"
                v-if="pedido && pedido.pedido_erro && pedido.pedido_erro == 1"
                ><i class="fa fa-times mr-1"></i>Remover Problema</b-btn
              >
            </div>
            <div
              class="combo"
              style="max-width: 160px"
            >
              <b-select
                :options="[
                  '',
                  'Não Atende',
                  'Pedido Errado',
                  'Reclamou Temperatura',
                  'Faltou Itens',
                  'Outros',
                ]"
                v-model="pedido.motivo_erro"
              ></b-select>
            </div>
          </div>

          <div
            class="box"
            v-show="!resumed"
          >
            <div class="box-titulo">
              <div><i class="fa fa-hand-holding"></i> Tipo de Entrega</div>
              <div
                class="right"
                v-show="tipo_entrega != null"
              >
                {{
                  tipo_entrega && tipo_entrega != null && tipo_entrega.titulo
                    ? tipo_entrega.titulo
                    : ""
                }}
              </div>
            </div>
            <div class="box-content">
              <div class="tipo-pedido">
                <label
                  :class="{ active: tipo_entrega == opc }"
                  @click="escolherTipoEntrega(opc)"
                  v-for="opc in opcoes_entrega"
                  :key="opc.titulo + opcoes_entrega.indexOf(opc)"
                >
                  <img
                    :src="require(`@/assets/img/icones/pedido/${opc.img}`)"
                  />
                  <br />
                  {{ opc && opc.titulo ? opc.titulo : "" }}
                </label>
              </div>
            </div>
          </div>
          <div
            class="box"
            :class="{ 'box-inativa': passo <= 0 }"
            v-show="!resumed"
          >
            <div class="box-titulo">
              <div><i class="fa fa-user"></i> Identificação Cliente</div>
              <div
                class="right"
                v-if="cliente && cliente.status != 'none'"
              >
                <ClienteFormStatus :status="cliente.status" />
              </div>
            </div>
            <div class="box-content">
              <div class="box-cliente">
                <div
                  class="text-center text-danger p-4"
                  v-if="
                    !(
                      tipo_entrega &&
                      tipo_entrega.titulo !== '' &&
                      tipo_entrega.titulo !== null
                    )
                  "
                >
                  Selecione a forma de entrega antes.
                </div>
                <ClienteForm
                  :enabled="
                    tipo_entrega &&
                    tipo_entrega.titulo !== '' &&
                    tipo_entrega.titulo !== null
                  "
                  ref="ClienteForm"
                  tipo="inside"
                  :Cliente="cliente"
                  :tipo_entrega="tipo_entrega"
                  @ch="changeCliente"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- <div
          class="col-12 col-lg-6 p-0 pt-0"
          style="min-height: 330px"
          v-if="onpedido && itensPai"
          :class="{ 'col-lg-12': mesa && mesa.id > 0 }"
        >
          <div class="box">
            <div class="box-titulo">
              <div><i class="fa fa-shopping-cart"></i> Itens do Pedido</div>
            </div>

         
          </div>
        </div> -->
        <div
          class="col-12 pl-0 pl-0 pr-0 pr-sm-3"
          v-if="mesa && mesa.id"
        >
          <div class="box">
            <div class="box-titulo">
              <div><i class="fa fa-barcode"></i> Comanda</div>
            </div>
            <div class="p-3">
              <div class="d-flex">
                <div
                  v-for="(item, index) in comandas"
                  :key="index"
                >
                  <b-btn
                    variant="outline-primary"
                    class="mr-1 mb-2"
                    :class="{
                      active:
                        comandaSelecionada && comandaSelecionada == item.value,
                    }"
                    @click="comandaSelecionada = item.value"
                  >
                    <i class="fa fa-barcode mr-1"></i>
                    {{ item.text }}
                  </b-btn>
                </div>
              </div>
              <!-- {{ comandaSelecionada }} -->
              <!-- {{ comandaSelecionada }}
              <b-select
                style="font-size: 20px"
                :options="comandas"
                v-model="comandaSelecionada"
              /> -->
            </div>
          </div>
        </div>
        <div
          class="col-12 col-lg-6 p-0 pt-0"
          :style="`min-height:${mesa && mesa.id > 0 ? '500' : '330'}px`"
          v-show="!resumed"
          :class="{ 'col-lg-12 mt-2': mesa && mesa.id > 0 }"
        >
          <div
            class="box boxAbs"
            :class="{ 'box-inativa': passo < 2 }"
          >
            <div
              class="box-titulo"
              :style="mesa && mesa.id > 0 ? 'min-height:50px' : ''"
            >
              <div><i class="fa fa-shopping-cart"></i> Itens do Pedido</div>
              <b-btn
                v-if="mesa && mesa.id > 0"
                rounded
                variant="success"
                @click="$bvModal.show('modal-add-item-mesa')"
                ><b-icon-plus-circle /> Adicionar Item</b-btn
              >
            </div>

            <Cardapio
              :Itens="itens"
              ref="CardapioMdl"
              :mesa="mesa"
              :empresaSelecionada="
                empresa_selecionada
                  ? empresa_selecionada.cod_empresa
                  : { cod_empresa: 0 }.cod_empresa
              "
              v-if="
                (pedido == null || (pedido != null && pedido.excluido !== 1)) &&
                //(!mesa || (mesa && !mesa.dt_fechada && (authAdm || !pedido)))
                !mesa
              "
            />
            <div :class="'itens ' + (mesa && mesa.id > 0 ? 'itens-mesa' : '')">
              <div
                class="no-item"
                v-if="!itensAgp || itensAgp.length <= 0"
              >
                Nenhum item inserido ainda.
              </div>
              <div
                v-for="grp in itensAgp"
                :key="`grp_item_${grp.grupo}`"
              >
                <div class="pedido-item-grupo">
                  {{ grp.grupo }}
                </div>
                <div
                  class="pedido-item"
                  v-for="item in grp.itens"
                  :key="`item_${grp.grupo}_${grp.itens.indexOf(item)}`"
                  :class="{
                    'pedido-item-bg-pizza': item.pizza,
                    'pedido-item-bg-refri': !item.pizza,
                  }"
                >
                  <div class="row h-100">
                    <div class="col-12 h-100 text-center">
                      <div
                        v-if="item.pizza"
                        class="mb-1"
                        style="font-weight: bold; font-size: 11px"
                      >
                        {{ item.preco_tipo.tipo }}
                        <span v-if="item.borda && item.borda.ingrediente">
                          - BORDA {{ item.borda.ingrediente }}
                        </span>
                      </div>
                      <div
                        class="row h-100"
                        v-if="item.pizza"
                      >
                        <div
                          class="h-100"
                          :class="{
                            'col-6': item.itens.length > 1,
                            'col-12': item.itens.length == 1,
                          }"
                        >
                          <span v-if="item.pizza">
                            {{ item.itens[0].cod_produto }} -
                          </span>

                          {{ item.itens[0].nome }}
                        </div>
                        <div
                          v-if="item.itens.length > 1"
                          class="col-6 pedido-item-meio h-100"
                        >
                          {{ item.itens[1].cod_produto }} -

                          {{ item.itens[1].nome }}
                        </div>
                      </div>
                    </div>
                    <div class="col-4 text-left pl-3 mt-2">
                      <div
                        v-if="!item.pizza"
                        class="font-bold"
                        style="font-weight: bold"
                      >
                        {{ item.itens[0].nome }}
                      </div>

                      <b-button
                        variant="primary"
                        size="sm"
                        @click="editar(item)"
                        v-if="
                          item.pizza &&
                          (pedido == null ||
                            (pedido != null && pedido.excluido !== 1)) &&
                          (!mesa || (mesa && !mesa.dt_fechada && authAdm))
                        "
                      >
                        <i class="fa fa-edit"></i> Editar
                      </b-button>
                    </div>
                    <div class="col-4 pt-2">
                      <quantidade
                        style="min-width: 104px"
                        v-if="
                          !pedido ||
                          (pedido && !mesa) ||
                          (pedido && mesa && authAdm)
                        "
                        :Model="item"
                        :remover="
                          () => {
                            removerItem(item);
                          }
                        "
                      />
                    </div>
                    <div class="col-4 mt-2">
                      <div class="text-right">
                        <div class="w-100"></div>
                        <div class="float-right w-100">
                          <small class="font-weight font-bold">
                            <div>
                              <small
                                >x R$
                                {{
                                  (item.pizza && item.vl_unitario
                                    ? item.vl_unitario
                                    : item.itens[0].preco_venda || 0) | currency
                                }}</small
                              >
                            </div>
                          </small>

                          R$ {{ item.vl_total | currency }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-8 mt-2 pl-3"
                      v-if="
                        item.itens[0].ingredientes &&
                        item.itens[0].ingredientes.find(
                          (x) => x.qtd && x.qtd > 0
                        )
                      "
                    >
                      <small>
                        <b>Adicionais</b><br />
                        <div class="pl-3">
                          <span
                            v-for="add in item.itens[0].ingredientes.filter(
                              (x) => x.qtd && x.qtd > 0
                            )"
                            :key="`ing_prod_${
                              add.cod_ingrediente
                            }_${Math.random(23232323232)}`"
                          >
                            {{ add.qtd }} x {{ add.ingrediente }}
                            |
                          </span>
                        </div>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="onpedido">
                <div class="pedido-item-grupo">Itens Online</div>
                <div
                  class="pedido-item"
                  v-for="item in itensPai"
                  :key="
                    `onPedido_pai_` +
                    itensPai.indexOf(item.cod_item) +
                    '_' +
                    item.cod_item
                  "
                >
                  <div class="row">
                    <div class="col">
                      {{ item.nome }}
                      <div
                        class="pl-2 mb-2"
                        v-if="item.obs && item.obs != 'false'"
                      >
                        {{ item.obs }}
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="text-right">
                        <div class="w-100"></div>
                        <div class="float-right w-100">
                          <div class="font-weight font-bold">
                            R$ {{ item.valor | currency }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <small>
                    <ul v-if="item.Filhos">
                      <li
                        v-for="it in item.Filhos"
                        :key="
                          `onPedido_filho_` +
                          '_' +
                          item.cod_item +
                          itensPai.indexOf(item.cod_item) +
                          '_' +
                          item.Filhos.indexOf(it)
                        "
                      >
                        {{ it.nome }} - {{ it.valor | currency }} -
                        <small
                          class="pl-4"
                          v-if="it.obs && it.obs != 'false'"
                        >
                          {{ it.obs }}
                        </small>
                      </li>
                    </ul>
                  </small>
                </div>
              </div>
            </div>

            <b-modal id="modal-item">
              <cardapio-item
                :item="item_edit"
                v-if="item_edit"
              />
            </b-modal>
          </div>
        </div>

        <div
          class="col-lg-6 pt-0"
          :class="{ 'col-lg-12': resumed || mesa }"
        >
          <div
            class="box mt-1"
            v-show="!resumed && !mesa"
          >
            <div class="box-titulo">
              <div>
                <i class="fa fa-stopwatch"></i> Previsão Entrega /
                <b-icon-calendar-check /> Agendamento
              </div>
            </div>
            <div class="row p-2">
              <div class="col-12 col-sm-6 pr-1">
                <b-input
                  type="text"
                  v-model="previsao_entrega"
                  class="text-center"
                  autocomplete="off"
                />
              </div>
              <div class="col-12 col-sm-6 pl-0">
                <b-button-group class="btsPrevisaoEntrega">
                  <b-button @click="addPrevisao(15)">15'</b-button>
                  <b-button @click="addPrevisao(20)">20'</b-button>
                  <b-button @click="addPrevisao(25)">25'</b-button>
                  <b-button @click="addPrevisao(30)">30'</b-button>
                  <b-button @click="addPrevisao(35)">35'</b-button>
                  <b-button @click="addPrevisao(40)">40'</b-button>
                  <b-button @click="addPrevisao(45)">45'</b-button>
                  <b-button @click="addPrevisao(50)">50'</b-button>
                  <b-button @click="addPrevisao(55)">55'</b-button>
                  <b-button @click="addPrevisao(60)">60</b-button>
                  <b-button @click="addPrevisao(65)">65'</b-button>
                </b-button-group>
              </div>
              <div
                class="col-12 text-danger p-3 text-left"
                style="font-size: 8px"
              >
                <div class="row">
                  <div
                    class="col-6"
                    v-for="empresa of empresas"
                    :key="'emp' + empresa.cod_empresa"
                  >
                    <i class="fa fa-clock" /> {{ empresa.nome }}
                    <span class="pl-4 pl-lg-1">{{
                      empresa.previsao_entrega
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <b-card class="m-0 mt-2 agendamentoClass">
                  <div class="w-100 cursor">
                    <b v-b-toggle:my-collapse
                      ><b-icon-calendar-check /> Agendamento</b
                    >
                  </div>
                  <b-collapse
                    id="my-collapse"
                    :visible="true"
                  >
                    <hr />
                    <div class="row text-left">
                      <div class="col-6 pr-0">
                        <b-form-group>
                          <label
                            ><b
                              ><b-icon-calendar class="mr-2" /> Data
                            </b></label
                          >
                          <b-input
                            type="date"
                            style="font-size: 10px"
                            class="text-center"
                            v-model="dt_agendamento"
                          />
                        </b-form-group>
                      </div>
                      <div class="col-6 pl-1">
                        <b-form-group>
                          <label
                            ><b><b-icon-clock class="mr-2" /> Hora </b></label
                          >
                          <b-input
                            type="time"
                            style="font-size: 10px"
                            class="text-center"
                            v-model="hr_agendamento"
                          />
                        </b-form-group>
                      </div>
                      <div class="col-12 mt-2">
                        <label
                          ><b
                            ><b-icon-chat-dots /> Observação Agendamento</b
                          ></label
                        >
                        <b-input v-model="agendamento_obs" />
                      </div>
                    </div>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </div>
          <div
            class="d-block d-sm-none mt-5 mb-5 pt-5 pb-5"
            v-if="mesa && mesa.id > 0"
          >
            <br />
            <br />
            <br />
          </div>
          <div
            class="box d-none d-lg-block mt-0 mb-0 pb-1 pr-1"
            :style="
              mesa && mesa.id > 0
                ? 'border-bottom-left-radius:0px; border-bottom-right-radius:0px'
                : ''
            "
            :class="{ 'd-block rodapeFixoMobile': mesa && mesa.id > 0 }"
          >
            <div
              class="box-titulo"
              v-show="!mesa"
            >
              <div><i class="fa fa-cash-register" /> Totais</div>
            </div>

            <div
              class="pedido-total subtotal"
              v-show="!mesa"
            >
              <small>Subtotal </small>
              R$ {{ vl_subtotal | currency }}
            </div>
            <div
              v-show="resumoMesaOpen"
              class="pedido-total"
            ></div>
            <div
              v-show="
                pedido && pedido.vl_acrescimo && (resumoMesaOpen || !mesa)
              "
              class="pedido-total total text-info desconto"
            >
              <small
                >Acrescimo (+)
                <div
                  v-if="
                    pedido &&
                    pedido.integration_data &&
                    pedido.integration_data.additionalFees
                  "
                >
                  <ul>
                    <li
                      v-for="(it, index) in pedido.integration_data
                        .additionalFees"
                      :key="index"
                    >
                      {{ it.description }} - (R$ {{ it.value | currency }})
                    </li>
                  </ul>
                </div>
              </small>

              <span v-if="!authAdm || (pedido && pedido.vl_acrescimo)">
                R$
                {{
                  pedido && pedido.vl_acrescimo
                    ? pedido.vl_acrescimo
                    : "0" | currency
                }}
              </span>
            </div>
            <div
              v-show="resumoMesaOpen || !mesa"
              class="pedido-total total text-danger desconto"
            >
              <small
                >Desconto (-)
                <div
                  v-if="
                    pedido &&
                    pedido.integration_data &&
                    pedido.integration_data.benefits
                  "
                >
                  <ul>
                    <li
                      v-for="(it, index) in pedido.integration_data.benefits"
                      :key="index"
                    >
                      <div
                        v-for="(it2, idx2) in it.sponsorshipValues"
                        :key="idx2"
                        v-show="it2.value > 0"
                        class="pr-2"
                      >
                        {{ it.campaign ? it.campaign.name + " - " : "" }}
                        {{ it2.description }} - (R$ {{ it2.value | currency }})
                      </div>
                    </li>
                  </ul>
                </div>
              </small>
              <b-input
                v-money="money"
                v-model="vl_desconto2"
                v-if="authAdm && (!pedido || pedido.sistema != 'IFOOD')"
              />
              <div v-if="pedido && pedido.sistema == 'IFOOD'">
                {{ pedido.vl_desconto | currency }}
              </div>
              <span v-if="!authAdm">
                <b-btn
                  variant="warning"
                  size="sm"
                  @click="solicitarLoginAdm"
                  ><b-icon-lock-fill
                /></b-btn>
                R$ {{ vl_desconto | currency }}
              </span>
            </div>

            <div
              v-if="mesa && mesa.id > 0"
              v-show="resumoMesaOpen"
              class="pedido-total text-center"
            >
              <b-icon-chat-dots /> Observações <br />
              <b-input
                style="font-size: 10px"
                v-model="observacoes"
                placeholder="digite qualquer observação"
              />
            </div>
            <div
              v-if="mesa && mesa.id > 0"
              class="text-right mt-3"
              @click="
                () => {
                  if (mesa && mesa.id > 0) {
                    resumoMesaOpen = !resumoMesaOpen;
                  }
                }
              "
            >
              <div
                class="bg-danger text-white p-2 text-center"
                v-if="pedido && pedido.excluido && pedido.excluido == 1"
              >
                PEDIDO CANCELADO
              </div>
              <span
                class="float-left text-center pl-2 pt-0"
                v-if="mesa && mesa.id > 0"
                style="font-size: 20px"
              >
                <b-icon-card-checklist v-if="!resumoMesaOpen" />
                <b-icon-x-circle v-if="resumoMesaOpen" />
                <span
                  style="font-size: 12px; vertical-align: middle; margin: 3px"
                  >Opções</span
                >
              </span>

              <b-button
                variant="success"
                @click="salvar()"
                :disabled="salvando"
                class="mr-1"
                v-if="
                  (!pedido || (pedido && !pedido.excluido)) &&
                  (!mesa || (mesa && !mesa.dt_fechada))
                "
              >
                <div v-if="!salvando"><b-icon-cart-plus /> Salvar</div>
                <div v-else>
                  <b-spinner
                    small
                    variant="text-white"
                  ></b-spinner>
                  Aguarde...
                </div>
              </b-button>

              <b-button
                variant="secondary"
                @click="cancelar()"
                ><i class="fa fa-ban" /> Cancelar</b-button
              >
            </div>

            <div
              class="pedido-total entrega desconto"
              v-if="
                tipo_entrega &&
                tipo_entrega.titulo &&
                tipo_entrega.titulo == 'ENTREGA'
              "
            >
              <small>Entrega </small>
              <span v-if="authAdm">
                <money
                  class="text-center p-1"
                  v-model="vl_entrega"
                  size="sm"
                  pattern="\d*"
                  v-bind="moneyMask"
                  style="font-size: 12px"
                  @blur.native="entregaChanged = true"
                />
              </span>
              <span v-else> {{ vl_entrega }} </span>
            </div>
            <div
              class="pedido-total entrega desconto"
              v-if="
                tipo_entrega &&
                tipo_entrega.titulo &&
                tipo_entrega.titulo == 'ENTREGA' &&
                authAdm &&
                pedido &&
                pedido.cod_pedido
              "
            >
              <small>Moto</small>
              <div>
                <b-select
                  v-model="cod_moto"
                  :options="motosDisponiveis"
                  @change.native="(e) => selectValorMoto(e)"
                  label="nome"
                />
                <b-btn
                  variant="none"
                  size="sm"
                  @click="$bvModal.show('modal-view-motos')"
                  ><b-icon-search
                /></b-btn>
                <b-modal
                  id="modal-view-motos"
                  hide-footer
                  size="xl"
                  @hidden="carregarMotos"
                >
                  <EntregasMotos
                    :data="pedido.data"
                    :empresa="empresa_selecionada"
                  />
                </b-modal>
              </div>
            </div>
            <div
              class="pedido-total entrega desconto"
              v-if="
                tipo_entrega &&
                tipo_entrega.titulo &&
                tipo_entrega.titulo == 'ENTREGA' &&
                authAdm &&
                pedido &&
                pedido.cod_pedido
              "
            >
              <small>Valor Moto </small>

              <money
                class="text-center p-1"
                v-model="vl_entrega_moto"
                size="sm"
                pattern="\d*"
                v-bind="moneyMask"
                style="font-size: 12px"
              />
            </div>
            <div
              class="pedido-total total"
              v-show="!mesa"
            >
              <small>Total Pedido </small> R$ {{ vl_total | currency }}
            </div>
            <div
              style="font-size: 30px"
              class="pt-0 pt-sm-3 mt-sm-3 d-flex justify-content-between pl-3 pr-3 mt-3"
            >
              <b-form-checkbox
                switch
                size="lg"
                large
                checked
                v-model="imprimir"
                :value="true"
                v-if="!editando && !mesa"
              >
                Impressão
              </b-form-checkbox>
              <b-form-checkbox
                switch
                size="lg"
                large
                checked
                v-model="emitirNota"
                :value="true"
                :unchecked-value="false"
                v-if="!editando && !mesa"
              >
                Cupom Fiscal
              </b-form-checkbox>

              <print-buttons
                v-if="editando"
                :codPedido="pedido.cod_pedido"
                :empresa="pedido.cod_empresa"
                :mesa="mesa != null"
                @updatePrint="saveAndPrint"
                ref="printButtons"
              />
              <div
                class="pl-1 pr-1"
                style="flex: 1; margin-left: 5px"
                v-if="pedido && (!mesa || (mesa && authAdm))"
              >
                <b-button
                  variant="danger"
                  @click="cancelarPedido"
                  block
                  v-if="
                    (!pedido || (pedido && !pedido.excluido)) &&
                    (!mesa || (mesa && !mesa.dt_fechada))
                  "
                >
                  <i class="fa fa-times" /> Cancelar Pedido
                </b-button>
                <b-button
                  variant="info"
                  @click="showCupom"
                  block
                  v-if="!mesa"
                >
                  <b-icon-file-binary /> Cupom Fiscal
                </b-button>
              </div>
              <b-btn
                block
                variant="info"
                style="flex: 1; margin-left: 5px"
                v-if="mesa && pedido && !authAdm"
                @click="solicitarLoginAdm"
                ><b-icon-file-lock2 /> Autorização ADM</b-btn
              >
            </div>
          </div>
        </div>

        <div
          class="col-12 col-lg-6"
          :class="{ 'col-lg-12': resumed }"
          v-show="!mesa"
        >
          <div :class="{ boxAbs: !resumed }">
            <div class="d-block d-lg-none">
              <div class="pedido-total subtotal">
                <small>Subtotal </small>
                R$ {{ vl_subtotal | currency }}
              </div>
              <div
                class="pedido-total entrega"
                v-if="
                  tipo_entrega &&
                  tipo_entrega.titulo &&
                  tipo_entrega.titulo == 'ENTREGA'
                "
              >
                <small>Entrega</small> R$ {{ vl_entrega }}
              </div>

              <div class="pedido-total total text-danger desconto">
                <small>Desconto [-] </small>
                <b-input
                  v-money="money"
                  v-model="vl_desconto1"
                  v-if="authAdm"
                />
                <span v-if="!authAdm">
                  <b-btn
                    variant="warning"
                    size="sm"
                    @click="solicitarLoginAdm"
                    ><b-icon-lock-fill
                  /></b-btn>
                  R$ {{ vl_subtotal | currency }}
                </span>
              </div>
              <div class="pedido-total total">
                <small>Total Pedido </small> R$ {{ vl_total | currency }}
              </div>
              <b-form-checkbox
                switch
                size="lg"
                large
                checked
                v-model="imprimir"
                :value="true"
                v-if="!editando"
              >
                Impressão
              </b-form-checkbox>
              <b-form-checkbox
                switch
                size="lg"
                large
                checked
                v-model="emitirNota"
                :value="true"
                :unchecked-value="false"
                v-if="!editando && !mesa"
              >
                Cupom Fiscal
              </b-form-checkbox>
              <b-button
                variant="info"
                class="mb-2"
                @click="showCupom"
                block
                v-if="pedido && (!mesa || (mesa && authAdm))"
              >
                <b-icon-file-binary /> Cupom Fiscal
              </b-button>
              <print-buttons
                v-if="editando"
                :codPedido="pedido.cod_pedido"
                :mesa="mesa != null"
                @updatePrint="saveAndPrint"
                ref="printButtons2"
              />
            </div>
            <div
              class="box mt-1"
              :class="{ 'box-inativa': passo < 2 }"
            >
              <div class="box-titulo">
                <div><i class="fa fa-money-bill-alt"></i> Pagamento</div>
              </div>

              <b-card
                no-body
                v-if="pagamento"
              >
                <b-tabs
                  pills
                  vertical
                  active-tab-class="m-0 p-0 t1"
                  content-class="p-3 pr-4 t1"
                  nav-wrapper-class="pr-0 t1"
                  nav-item-class="text-left t1"
                  active-nav-item-class="text-left t1 tt"
                  v-model="forma_pagamento"
                  style="font-size: 0.8rem !important"
                >
                  <b-tab
                    title="Não Informado"
                    v-if="
                      tipo_entrega &&
                      tipo_entrega.titulo &&
                      tipo_entrega.titulo == 'BUSCAR'
                    "
                  >
                    <template #title>
                      <div class="text-left">
                        <i class="fa fa-times"></i> Não Informado
                      </div>
                    </template>
                    <b-card-text>Basta finalizar o pedido</b-card-text></b-tab
                  >
                  <b-tab
                    title="Dinheiro"
                    active
                  >
                    <template #title>
                      <div class="text-left">
                        <i class="fa fa-money-bill-wave"></i> Dinheiro
                      </div>
                    </template>
                    <b-card-text>
                      <label>Valor Dinheiro</label>
                      <b-input
                        placeholder="valor"
                        v-money="money"
                        class="text-center"
                        v-model="pagamento.vl_dinheiro"
                        ref="vlDim"
                        @focus="focus('vlDim')"
                      />
                      <label>Troco</label>
                      <span class="form-control">{{ vl_troco }}</span>
                    </b-card-text></b-tab
                  >
                  <b-tab title="Cartão">
                    <template #title>
                      <div class="text-left">
                        <i class="fa fa-credit-card"></i> Cartão
                      </div>
                    </template>

                    <b-card-text>
                      <label>Valor Cartão</label>
                      <b-input
                        placeholder="valor"
                        v-money="money"
                        class="text-center"
                        v-model="pagamento.vl_cartao"
                        ref="vlCart"
                        @focus="focus('vlCart')"
                      />
                    </b-card-text>

                    <div
                      v-if="
                        pedido &&
                        pedido.integration_data &&
                        pedido.integration_data.payments &&
                        pedido.integration_data.payments.methods &&
                        pedido.integration_data.payments.methods.length > 0 &&
                        pedido.integration_data.payments.methods[0].card &&
                        pedido.integration_data.payments.methods[0].card.brand
                      "
                      class="text-center p-4"
                    >
                      <b>Bandeira Informada</b>

                      <h4>
                        {{
                          pedido.integration_data.payments.methods[0].card.brand
                        }}
                      </h4>
                    </div>
                  </b-tab>
                  <b-tab title="Dinheiro + Cartão">
                    <template #title>
                      <div class="text-left">
                        <i class="fa fa-money-check-alt"></i> Din. + Cartão
                      </div>
                    </template>
                    <b-card-text>
                      <label>Valor Dinheiro</label>
                      <b-input
                        placeholder="valor"
                        v-money="money"
                        class="text-center"
                        v-model="pagamento.vl_dinheiro"
                        ref="vlDim2"
                        @focus="focus('vlDim2')"
                      />
                      <label>Valor Cartão</label>
                      <b-input
                        placeholder="valor"
                        v-money="money"
                        class="text-center"
                        v-model="pagamento.vl_cartao"
                        ref="vlCart2"
                        @focus="focus('vlCart2')"
                      />

                      <label>Troco</label>
                      <span class="form-control">{{ vl_troco }}</span>
                    </b-card-text></b-tab
                  >
                  <b-tab title="Vale">
                    <template #title>
                      <div class="text-left">
                        <i class="fa fa-wallet"></i> Vale
                      </div>
                    </template>
                    <b-card-text>Selecione o Cliente</b-card-text></b-tab
                  >

                  <b-tab title="Online">
                    <template #title>
                      <div class="text-left">
                        <i class="fa fa-globe"></i> Online
                      </div>
                    </template>
                    <b-card-text>Basta finalizar o pedido</b-card-text></b-tab
                  >
                  <b-tab title="Só Entregar">
                    <template #title>
                      <div class="text-left">
                        <i class="fa fa-hand-holding"></i> Só Entregar
                      </div>
                    </template>
                    <b-card-text>Basta finalizar o pedido</b-card-text></b-tab
                  >
                  <b-tab title="PIX">
                    <template #title>
                      <div class="text-left">
                        <i class="fa fa-qrcode"></i> PIX
                      </div>
                    </template>
                    <div class="row">
                      <div class="col-12">
                        <PixPayment
                          :valor="vl_total"
                          :clienteNome="cliente.Nome"
                          :clienteTelefone="cliente.Telefone"
                          @concluido="setPixInfo"
                          ref="PixPyamentOBJ"
                        />
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="VR / Sysprocard">
                    <template #title>
                      <div class="text-left">
                        <i class="far fa-address-card"></i> Vale Refeição
                      </div>
                    </template>
                    <b-card-text>
                      <b-form-group label="Número / Autorização">
                        <b-input v-model="pagamento.dados_pagamento.vale" />
                      </b-form-group> </b-card-text
                  ></b-tab>
                </b-tabs>
              </b-card>

              <div class="row mt-2 mb-2 p-2">
                <div class="col-12 text-left">
                  <label class="text-left">Observações</label>
                  <label class="float-right">
                    <b-check
                      switch
                      v-model="alerta"
                    >
                      Notificar
                    </b-check>
                  </label>
                  <textarea
                    v-model="observacoes"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="text-right mt-3 p-2">
                <b-button
                  size="lg"
                  variant="outline-primary"
                  block
                  @click="salvar()"
                  :disabled="salvando"
                >
                  <div v-if="!salvando">
                    <i class="fa fa-save"></i>
                    {{ " " }}
                    <span v-if="!pedido">FINALIZAR</span>
                    <span v-else-if="pedido && onpedido && pedido.status == 0"
                      >CONFIRMAR PEDIDO</span
                    >
                    <span v-else>ATUALIZAR</span>
                  </div>
                  <div v-else>
                    <b-spinner
                      small
                      variant="text-white"
                    ></b-spinner>
                    Aguarde...
                  </div>
                </b-button>
                <b-button
                  size="lg"
                  variant="outline-primary"
                  block
                  @click="verLog()"
                  v-if="pedido && pedido.cod_pedido > 0"
                >
                  <b-icon-clock-history class="mr-1" />
                  Histórico de Alteração
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row"></div>
    </div>
    <b-modal
      id="modal-verificar-empresa"
      title="Confirmação da Empresa"
      @ok="confirmaEmpresa"
    >
      <empresa-combo
        v-show="!resumed"
        ref="empresaCombo"
        :selecionar="selecionarEmpresa"
        :selected="pedido ? pedido.cod_empresa : null"
        class=""
      />
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          variant="primary"
          @click="
            () => {
              ok();
            }
          "
        >
          <i class="fa fa-check"></i> Confirmar
        </b-button>
        <b-button
          variant="secondary"
          @click="cancel()"
        >
          <i class="fa fa-ban"></i> Cancelar
        </b-button>
      </template>
    </b-modal>
    <login-admin
      v-if="loginAdm"
      @result="loginResult"
      :idExterno="pedido ? pedido.cod_pedido : null"
      :tipo="'pedido'"
      :obs="
        pedido && pedido.cod_pedido
          ? 'Edição de pedido'
          : 'Autorização de pedido novo'
      "
      @setSolicitacaoID="solicitacao_id = $event"
    />

    <Cardapio
      v-show="false"
      v-if="mesa && mesa.id > 0"
      :Itens="itens"
      ref="CardapioMdl3"
      :mesa="mesa"
      :empresaSelecionada="
        empresa_selecionada
          ? empresa_selecionada.cod_empresa
          : { cod_empresa: 0 }.cod_empresa
      "
      @closeModalBehind="
        () => {
          this.$bvModal.hide('modal-add-item-mesa');
        }
      "
    />
    <b-modal
      id="modal-add-item-mesa"
      v-if="mesa && mesa.id > 0"
      hide-footer
      hide-title
      title="Selecione um tipo"
    >
      <Cardapio
        :Itens="itens"
        ref="CardapioMdl2"
        :mesa="mesa"
        :empresaSelecionada="
          empresa_selecionada
            ? empresa_selecionada.cod_empresa
            : { cod_empresa: 0 }.cod_empresa
        "
        @closeModalBehind="
          () => {
            this.$bvModal.hide('modal-add-item-mesa');
          }
        "
        v-if="
          (pedido == null || (pedido != null && pedido.excluido !== 1)) &&
          (!mesa || (mesa && !mesa.dt_fechada && (authAdm || !pedido)))
        "
      />
      <b-btn
        variant="outlike"
        block
        @click="$bvModal.hide('modal-add-item-mesa')"
        ><b-icon-x /> Fechar</b-btn
      >
    </b-modal>
    <b-modal
      id="modal-fiscal"
      title="Cupom Fiscal"
      hide-footer
      v-if="CupomOpen"
      size="xl"
    >
      <CupomFiscal
        :PedidoID="CupomOpen.cod_pedido"
        :CodEmpresa="CupomOpen.cod_empresa"
      />
    </b-modal>
    <b-modal
      id="modal-open-caixa"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
    >
      <div class="text-center">
        <h2><b-icon-exclamation-circle /> Caixa não aberto!</h2>
        <hr />
      </div>
      <div>
        <div class="titulo">
          <div class="texto pl-2">
            <i class="fas fa-cash-register mr-2"></i> Caixa
          </div>
          <div class="combo">
            <caixa-combo
              :CaixasLista="caixasDisponiveis"
              :caixa="
                $store.state.caixa_ativo
                  ? $store.state.caixa_ativo.cod_caixa || 0
                  : 0
              "
              @ch="changeCaixa($event)"
              :ComTodos="false"
            />
          </div>
        </div>
        <div class="titulo">
          <div class="texto pl-2">
            <i class="fas fa-coins mr-2"></i> Valor Abertura
          </div>
          <div class="combo">
            <money
              class="text-center form-control p-1"
              v-model="vl_fundo_caixa"
              size="sm"
              pattern="\d*"
              v-bind="moneyMask"
              style="font-size: 12px"
              @blur.native="entregaChanged = true"
            />
          </div>
        </div>
        <hr />
        <div class="text-right">
          <b-btn
            variant="success"
            class="mr-1"
            @click="openCaixa"
            ><b-icon-check /> Abrir Caixa</b-btn
          >
          <b-btn
            variant="secondary"
            @click="cancelarOpenCaixa"
            ><b-icon-x-circle /> Cancelar</b-btn
          >
        </div>
      </div>
    </b-modal>
    <b-modal
      hide-footer
      id="modal-log"
      size="xl"
      title="Histórico de Alterações"
    >
      <pedido-log :pedido="pedido"></pedido-log>
    </b-modal>
    <b-modal
      id="modal-cancelamento-ifood"
      title="Motivo cancelamento"
      hide-footer
    >
      <b>Selecione o motivo do cancelamento no ifood</b>

      <v-select
        size="lg"
        v-model="razaoCancelamento.selecionado"
        item-label="description"
        label="description"
        :options="razaoCancelamento.data"
      />
      <b-btn
        class="mt-4"
        block
        variant="danger"
        @click="cancelarIfood"
        >CANCELAR PEDIDO</b-btn
      >
      <b-btn
        block
        variant="secondary"
        @click="$bvModal.hide('modal-cancelamento-ifood')"
        >Fechar</b-btn
      >
    </b-modal>
  </div>
</template>

<script>
import ClienteForm from "../../components/clientes/ClienteForm.vue";
import CaixaLib from "../../libs/Caixas";
// import ProdutoLib from "../../libs/ProdutoLib";
import ClienteFormStatus from "../../components/clientes/ClienteFormStatus.vue";
import Cardapio from "../../components/pedido/Cardapio.vue";
import Quantidade from "../common/Quantidade.vue";
import CardapioItem from "./CardapioItem.vue";
import validador from "@/utils/validador";
import Pedido from "@/libs/Pedido";

import moment from "moment";
import EmpresaCombo from "../empresa/EmpresaCombo.vue";
import CaixaCombo from "../empresa/CaixaCombo.vue";

// import axios from "../../utils/axios";
import PrintButtons from "./PrintButtons.vue";
import LoginAdmin from "../LoginAdmin.vue";
import PedidoAddItemNovo from "./PedidoAddItemNovo.vue";
import PixPayment from "../Caixas/PixPayment.vue";
import CupomFiscal from "../Caixas/CupomFiscal.vue";

import EntregaLib from "../../libs/Entregas";
// import EntregaMotoControle from "../../views/Entregas/EntregaMotoControle.vue";
import EntregasMotos from "../../views/Entregas/EntregasMotos.vue";
import PedidoLog from "./PedidoLog.vue";
export default {
  name: `Pedido${Math.random()}`,
  props: {
    PedidoOpen: Object,
    CloseOnSave: Function,
    resumed: {
      type: Boolean,
      default: false,
    },
    mesa: {
      type: Object,
      default: null,
    },
    comandaSel: Object,
  },
  components: {
    ClienteForm,
    ClienteFormStatus,
    Cardapio,
    Quantidade,
    CardapioItem,
    EmpresaCombo,
    CaixaCombo,
    PrintButtons,
    LoginAdmin,
    PedidoAddItemNovo,
    PixPayment,
    CupomFiscal,
    EntregasMotos,
    PedidoLog,
    // EntregaMotoControle,
  },
  data() {
    return {
      horaAgora: moment().format("HH"),
      horaPedido: moment().format("HH"),
      moneyMask: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: true,
      },
      caixasDisponiveis: this.$store.state.caixas,
      motosDisponiveis: [],
      motosDisponiveisRaw: [],
      CupomOpen: null,
      id: 0,
      item_edit: null,
      cliente: { status: "none", Nome: "", Telefone: "" },
      empresaConfirmada: false,
      pagamento: {
        vl_dinheiro: 0,
        vl_cartao: 0,
        vl_troco: 0,
        troco: 0,
        dados_pagamento: {
          vale: "",
          PIX: null,
        },
      },
      imprimir: true,
      emitirNota: false,
      forma_pagamento: -1,
      passo: 0,
      tipo_entrega: {
        titulo: null,
      },

      itensAgp: [],
      vl_subtotal: 0,
      vl_acrescimo: 0,
      vl_entrega: "R$ 0,00",
      vl_entrega_padrao: "R$ 9,00",
      previsao_entrega: "",
      itens: [],
      itensPai: [],
      onpedido: false,
      loading: false,
      alerta: false,
      observacoes: "",
      cod_moto: 0,

      cod_caixa: this.$store.state.caixa_ativo
        ? this.$store.state.caixa_ativo.cod_caixa
        : 1,

      dt_agendamento: null,
      hr_agendamento: null,
      agendamento_obs: "",

      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      pedido: null,
      salvando: false,
      user: this.$store.state.auth.user,
      cardapio: this.$store.state.cardapio.map((it) => {
        return {
          ...it,
          produtos: it.produtos.filter((x) =>
            this.mesa && x.tipo_venda ? x.tipo_venda.includes("MESA") : 1 == 1
          ),
        };
      }),
      empresas: [
        this.$store.state.empresas,
        ...this.$store.state.empresas.empresas,
      ],
      empresa_selecionada: null,
      editando: false,
      opcoes_entrega: [
        {
          titulo: "BALCÃO",
          img: "ticket_purchase_52px.png",
        },
        {
          titulo: "BUSCAR",
          img: "handle_with_care_52px.png",
        },
        {
          titulo: "ENTREGA",
          img: "delivery_scooter_52px.png",
        },
      ],
      listaRemoveItem: [],
      comandas: [],
      comandaSelecionada: null,
      podeFechar:
        this.$store.state.auth.user.tipo.indexOf("Admin") >= 0 ||
        this.$store.state.auth.user.tipo.indexOf("Gerente") >= 0 ||
        this.$store.state.auth.user.tipo.indexOf("Caixa") >= 0,
      podeRetirarPorc:
        this.$store.state.auth.user.tipo.indexOf("Admin") >= 0 ||
        this.$store.state.auth.user.tipo.indexOf("Gerente") >= 0 ||
        this.$store.state.auth.user.tipo.indexOf("Caixa Mesas") >= 0 ||
        this.$store.state.auth.user.tipo.indexOf("Caixa") >= 0,
      loginAdm: false,
      authAdm:
        this.$store.state.auth.user.tipo.indexOf("Admin") >= 0 ||
        this.$store.state.auth.user.tipo.indexOf("Gerente") >= 0,
      solicitacao_id: null,
      vl_desconto: 0,
      vl_desconto1: 0,
      vl_desconto2: 0,
      vl_desconto3: 0,
      resumoMesaOpen: false,
      entregaChanged: false,
      forceChangeCaixa: false,
      vl_fundo_caixa: 0,
      data: moment().format("YYYY-MM-DD"),
      razaoCancelamento: {
        loading: true,
        data: [],
        selecionado: null,
      },
    };
  },
  // async beforeCreate() {
  //   // console.log("before create", this.$store.state.cardapio);
  //   // if (!this.$store.state.cardapio) {
  //   //   this.$route.push("/");
  //   // }
  // },
  mounted() {
    this.user = this.$store.state.auth.user;
    this.cod_caixa = this.$store.state.caixa_ativo.cod_caixa;
    console.log("caixa", this.cod_caixa);

    this.empresas = [
      this.$store.state.empresas,
      ...this.$store.state.empresas.empresas,
    ];
    // axios.post("/auth/empresa", { tipo: "usuario" }).then((data) => {
    //   // this.$store.state.empresas = [data.data, ...data.data.empresas];
    //   this.empresas = [data.data, ...data.data.empresas];
    //   // console.log("empresas atualizadas");
    // });
    (async () => {
      this.$store.state.loading = true;
      try {
        console.log("card", this.$store.state.cardapio);
        if (
          !this.$store.state.cardapio ||
          this.$store.state.cardapio.length <= 0
        ) {
          await this.$store.dispatch("auth/atualizarCardapio", true);
          console.log("card2", this.$store.state.cardapio);
        }
      } catch (e) {
        console.log("ERRO", e);
      }
      this.$store.state.loading = false;
    })();

    //if(this.user.tipo == )
    console.log("ISMESA????", this.mesa);
    if (this.mesa && this.mesa.id > 0) {
      if (!this.$props.PedidoOpen) {
        this.$bvModal.show("modal-add-item-mesa");
      }
      this.comandas = [
        { value: -1, text: "NENHUMA" },
        ...(this.mesa
          ? this.mesa.comandas.map((it) => {
              return { value: it.id, text: it.nome };
            })
          : []),
      ];

      if (this.comandas && this.comandas.length > 1) {
        this.comandaSelecionada = this.comandas[1].value;
      }
      if (this.comandaSel) {
        this.comandaSelecionada = this.comandas.find(
          (x) => x.value && x.value == this.comandaSel.id
        ).value;
        console.log("COMANDA SETTEED", this.comandaSel);
        console.log("COMANDA SETTEED", this.comandaSelecionada);
      }
    }

    // console.log("usuario", this.user);
    // Cardapio;
    this.verificarAberturaCaixa();

    if (this.$props.PedidoOpen) {
      this.pedido = this.$props.PedidoOpen;
      this.horaPedido = moment(this.pedido.data).format("HH");

      //console.log(this.pedido);
      this.loading = true;
      setTimeout(() => {
        this.carregarPedido();
      }, 200);
    }
  },
  watch: {
    "pedido.motivo_erro": function () {
      this.pedido.pedido_erro =
        this.pedido.motivo_erro != null && this.pedido.motivo_erro != ""
          ? 1
          : 0;
    },
    "$props.PedidoOpen": function () {
      if (this.$props.PedidoOpen) {
        this.pedido = this.$props.PedidoOpen;
        this.carregarPedido();
      }
    },
    vl_desconto: function () {
      this.calcularTotal();
    },
    vl_desconto1: function () {
      this.vl_desconto = this.vl_desconto1;
    },
    vl_desconto2: function () {
      this.vl_desconto = this.vl_desconto2;
    },
    "$store.state.cardapio": function () {
      this.cardapio = this.$store.state.cardapio.map((it) => {
        return {
          ...it,
          produtos: it.produtos.filter((x) =>
            this.mesa && x.tipo_venda ? x.tipo_venda.includes("MESA") : 1 == 1
          ),
        };
      });
      // //console.log("cardapio", this.cardapio);
    },
    vl_entrega: function () {
      // console.log("ENTREGA CHANGE", this.vl_entrega);
    },
    tipo_entrega: function () {
      if (this.tipo_entrega.titulo == "ENTREGA") {
        this.empresa_selecionada = this.empresas.find(
          (x) => x.cod_empresa == 1
        );
        this.empresaConfirmada = false;
        this.selecionarEmpresa(this.empresas.find((x) => x.cod_empresa == 1));
      } else {
        this.selecionarEmpresa(
          this.empresas.find((x) => x.cod_empresa == this.user.cod_empresa)
        );
      }
      //if (!this.pedido) {
      this.vl_entrega =
        this.tipo_entrega &&
        this.tipo_entrega.titulo &&
        this.tipo_entrega.titulo == "ENTREGA"
          ? this.vl_entrega_padrao
          : 0;
      this.$nextTick(() => {
        if (
          this.tipo_entrega &&
          this.tipo_entrega.titulo &&
          this.tipo_entrega.titulo == "BUSCAR"
        ) {
          this.forma_pagamento = 0;
        }
      });
      //}
    },
    itens: {
      deep: true,
      handler() {
        console.log("itens cru", this.itens);
        // if (!this.onpedido) {
        {
          this.itensAgp = this.itens.reduce((ret, vl) => {
            vl.vl_total =
              (vl.pizza ? vl.vl_unitario : vl.itens[0].preco_venda) * vl.qtd;
            let grp = ret.find((x) => x.grupo == vl.grupo);
            if (!grp) {
              ret.push({ grupo: vl.grupo, itens: [vl] });
            } else {
              grp.itens.push(vl);
            }
            return ret;
          }, []);
        }
        this.calcularTotal();

        // console.log("itssssssssssss", this.itensPai);

        // //console.log(this.itensAgp);
      },
    },
    vl_total: function () {
      //console.log(this.forma_pagamento);
      // this.pagamento.vl_dinheiro = "R$ 0,00";
      //let soma = 0;
      // if (this.tipo_entrega.titulo == "BUSCAR") {
      //   soma = 1;
      // }
      // if (
      //   this.forma_pagamento == soma + 2 ||
      //   this.forma_pagamento == soma + 1
      // ) {
      //   this.pagamento.vl_cartao =
      //     "R$ " + this.vl_total.toFixed(2).replace(".", ",");
      // } else if (this.forma_pagamento == soma) {
      //   this.pagamento.vl_dinheiro =
      //     "R$ " + this.vl_total.toFixed(2).replace(".", ",");
      // }
    },
    forma_pagamento: function () {
      let soma = 0;
      if (
        this.tipo_entrega &&
        this.tipo_entrega.titulo &&
        this.tipo_entrega.titulo == "BUSCAR"
      ) {
        soma = 1;
      }
      if (
        this.forma_pagamento == soma + 1 ||
        this.forma_pagamento == soma + 2
      ) {
        this.pagamento.vl_cartao =
          "R$ " + this.vl_total.toFixed(2).replace(".", ",");
      } else if (this.forma_pagamento == soma) {
        this.pagamento.vl_dinheiro =
          "R$ " + this.vl_total.toFixed(2).replace(".", ",");
      }
    },
    pagamento: {
      deep: true,
      handler() {},
    },
    pedido: {
      deep: true,
      handler() {
        if (
          this.pedido &&
          this.pedido.integration_data &&
          typeof this.pedido.integration_data == "string" &&
          this.pedido.integration_data.length > 0
        ) {
          this.pedido.integration_data = JSON.parse(
            this.pedido.integration_data
          );
        }
      },
    },
  },
  computed: {
    vl_troco: function () {
      let vl = 0;
      try {
        vl =
          this.getNumberFromValor(this.pagamento.vl_dinheiro) -
          this.vl_total +
          ((this.tipo_entrega &&
            this.tipo_entrega.titulo &&
            this.forma_pagamento == 2 &&
            this.tipo_entrega.titulo != "BUSCAR") ||
          (this.tipo_entrega &&
            this.tipo_entrega.titulo &&
            this.forma_pagamento == 3 &&
            this.tipo_entrega.titulo == "BUSCAR")
            ? this.getNumberFromValor(this.pagamento.vl_cartao)
            : 0);
      } catch (e) {
        //console.log(e);
      }
      if (vl < 0) vl = 0;
      return "R$ " + vl.toFixed(2).replace(".", ",");
    },
    vl_total: function () {
      // console.log("desconto calc", this.vl_desconto);
      return (
        this.vl_subtotal +
        (this.pedido && this.pedido.vl_acrescimo
          ? this.pedido.vl_acrescimo
          : 0) +
        (typeof this.vl_entrega == "string"
          ? this.formatValorReal(this.vl_entrega)
          : this.vl_entrega) -
        (this.vl_desconto ? this.getNumberFromValor(this.vl_desconto) : 0)
      );
    },
  },
  methods: {
    async carregarRazaoCancelamento() {
      this.razaoCancelamento.loading = true;
      try {
        let res = await Pedido.getRazaoCancelamentoIfood(this.PedidoOpen);
        console.log("res razao cancelamento", res);
        if (res && res.length > 0) {
          this.razaoCancelamento.data = res;
          this.$bvModal.show("modal-cancelamento-ifood");
        } else {
          this.$swal(
            "Erro!",
            "Não foi possível carregar os motivos de cancelamento",
            "error"
          );
        }
      } catch (error) {
        console.log("error on get razao cancelamento", error);
      }
      this.razaoCancelamento.loading = false;
    },
    async selectValorMoto(e) {
      if (e && e.target) this.cod_moto = e.target.value;
      // console.log("selectingmoto",this.cod_moto,e.target.value);
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.cod_moto && this.motosDisponiveisRaw) {
            let fid = this.motosDisponiveisRaw.find(
              (x) => x.cod_moto == this.cod_moto
            );
            //  console.log('fnd', fid);
            if (fid) {
              this.vl_entrega_moto = this.formatFloatToReal(fid.vl_entrega);
              this.$forceUpdate();
              // console.log('vl', this.vl_entrega_moto)
            }
          }
        }, 20);
      });
    },
    async carregarMotos() {
      try {
        let res = await EntregaLib.getMotosDisponiveis({
          data: moment(
            this.pedido && this.pedido.data ? this.pedido.data : ""
          ).format("YYYY-MM-DD HH:mm:ss"),
        });
        // console.log("MotosCarregadas", res);
        if (res && res.success) {
          this.motosDisponiveisRaw = res.data;
          this.motosDisponiveis = res.data
            .filter((x) => x.cod_entrada)
            .map((it) => ({
              value: it.cod_moto,
              text: it.nome,
            }));
        }
      } catch (err) {
        console.log("Erro on get Motos", err);
      }
    },
    changeCliente(cli) {
      // console.log(cli);

      this.cliente = cli;
      this.passo = cli.status == "not" || cli.status == "ok" ? 2 : 1;
    },
    showCupom() {
      this.$bvModal.show("modal-fiscal");
    },
    setPixInfo(dados) {
      this.pagamento.dados_pagamento = { PIX: dados };
      // console.log(dados);
    },
    cancelarOpenCaixa() {
      this.$swal(
        "Caixa não aberto!",
        "Você não pode continuar sem efetuar abertura do caixa!",
        "error"
      ).then(() => {
        window.history.back();
      });
    },
    async openCaixa() {
      await CaixaLib.storeFechamento({
        fechamento: {
          print: false,
          cod_empresa: this.empresa_selecionada.cod_empresa,
          cod_caixa: this.cod_caixa,
          cod_usuario: this.user.cod_usuario,
          data: moment().format("YYYY-MM-DD HH:mm:ss"),
          dt_caixa: moment()
            .add(parseInt(moment().format("HH")) < 8 ? -1 : 0, "d")
            .format("YYYY-MM-DD"),
          dt_cadastro: moment().format("YYYY-MM-DD HH:mm:ss"),
          cod_usuario_cadastro: this.user.cod_usuario,
          vl_aberto: this.formatValorReal(this.vl_fundo_caixa),
        },
      });

      this.verificarAberturaCaixa();
    },
    async verificarAberturaCaixa() {
      /* const convertRealToFloat = (vl) => {
        if (!vl) return 0;
        vl = parseFloat(
          vl.toString().replace("R$", "").replace(".", "").replace(",", ".")
        );
        // console.log(!vl || vl == "NaN" ? 0 : vl);
        return !vl || vl == "NaN" ? 0 : vl;
      };*/

      // const convertFloatToReal = (vl) => {
      //   if (!vl) return "R$0,00";
      //   vl = vl.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
      //   return vl;
      // };

      if (
        ((this.user && this.user.tipo == "Gerente Caixa") ||
          this.user.tipo == "Caixa") &&
        this.user.cod_caixa != 4 &&
        this.user.cod_caixa != 5
      ) {
        // console.log(
        //   "finding",
        //   this.empresa_selecionada.cod_empresa,
        //   moment().format("YYYY-MM-DD"),
        //   this.cod_caixa
        // );
        let caixaControle = [];
        let hasConsulta = localStorage.getItem("aberturaCaixa");
        if (hasConsulta) {
          hasConsulta = JSON.parse(hasConsulta);
        }
        if (!hasConsulta) {
          hasConsulta = [];
        }
        // console.log("user cx", this.user);
        if (hasConsulta) {
          let hasCaixa = hasConsulta.find(
            (x) =>
              x.cod_empresa == this.user.cod_empresa &&
              x.data ==
                moment()
                  .add(parseInt(moment().format("HH") < 8 ? -1 : 0), "d")
                  .format("YYYY-MM-DD") &&
              x.cod_caixa == this.user.cod_caixa
          );
          console.log("hasCaixa", hasCaixa, hasConsulta);
          if (!hasCaixa || (hasCaixa && hasCaixa.opened == false)) {
            console.log("PRECISOU BUCSAR NO BANCO!!!!!!!!!!!!!!!!!!!!!!!!");
            caixaControle = await CaixaLib.getFechamentos(
              this.user.cod_empresa,
              moment()
                .add(parseInt(moment().format("HH") < 8 ? -1 : 0), "d")
                .format("YYYY-MM-DD"),
              this.user.cod_caixa,
              true
            );
            if (!hasCaixa) {
              hasConsulta.push({
                cod_empresa: this.user.cod_empresa,
                data: moment()
                  .add(parseInt(moment().format("HH") < 8 ? -1 : 0), "d")
                  .format("YYYY-MM-DD"),
                cod_caixa: this.user.cod_caixa,
                opened: caixaControle.length > 0,
              });
            } else {
              hasCaixa.opened = caixaControle.length > 0;
            }
            localStorage.setItem("aberturaCaixa", JSON.stringify(hasConsulta));
          } else {
            caixaControle = [1];
          }
        }

        // console.log("caixaControle", caixaControle);
        if (caixaControle.length <= 0) {
          this.$bvModal.show("modal-open-caixa");
          return;

          /*
          this.$swal({
            title: "Caixa não aberto!",
            input: "text",
            inputLabel: "Fundo de Caixa / Troco",
            inputValue: "0,00",
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value || convertRealToFloat(value) <= 0) {
                return "Informe um valor válido!";
              }
            },
            inputAttributes: {
              "v-money": "moneyMask",
            },
            confirmButtonText: "Abrir Caixa",
            cancelButtonText: "Cancelar",
          }).then(async (x) => {
            // console.log("fundo tr", x);
            if (x.isConfirmed && x.value && convertRealToFloat(x.value) > 0) {
              console.log(x);
            } else {
              this.$swal({
                title: "Caixa não aberto!",
              }).then(() => {
                window.history.back();
              });
            }
          });*/
        } else {
          this.$bvModal.hide("modal-open-caixa");
        }
      }
    },
    loginResult(result) {
      this.loginAdm = false;
      this.authAdm = !result ? false : result ? true : false;
    },
    focus(inp) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs[inp].select();
        }, 100);
      });
    },
    changeCaixa(caixa) {
      if (caixa && caixa.value) {
        if (!this.pedido || !this.pedido.cod_pedido) {
          this.$store.state.caixa_ativo = this.$store.state.caixas.find(
            (x) => x.cod_caixa === caixa.value
          );
          this.verificarAberturaCaixa();
        } else {
          this.forceChangeCaixa = true;
        }
        console.log("change caixa", this.cod_caixa, caixa);
        this.cod_caixa = caixa.value;
      }
    },
    reset() {
      this.listaRemoveItem = [];
      this.empresaConfirmada = false;
      this.id = 0;
      this.item_edit = null;
      this.cliente.status = "none";
      this.cliente.Nome = "";
      this.cliente.Telefone = "";
      this.cliente.CGC = "";
      this.cliente.Bairro = "";
      this.cliente.Logradouro = "";
      this.cliente.Numero = "";
      this.cliente.CEP = "";
      this.cliente.complemento = "";
      this.$forceUpdate();
      this.pagamento.vl_dinheiro = 0;
      this.pagamento.vl_cartao = 0;
      this.pagamento.vl_troco = 0;
      this.pagamento.troco = 0;
      this.imprimir = true;
      this.forma_pagamento = 0;
      this.passo = 0;
      this.tipo_entrega = null;
      this.itensAgp = [];
      this.vl_subtotal = 0;
      this.vl_acrescimo = 0;
      this.vl_entrega = 0;
      this.vl_entrega_moto = 0;
      this.entregaChanged = false;
      this.forceChangeCaixa = false;

      this.dt_agendamento = null;
      this.hr_agendamento = null;
      this.agendamento_obs = "";

      this.previsao_entrega = "";
      this.itens = [];
      this.editando = false;
      this.observacoes = "";
      this.alerta = false;
      this.authAdm = false;
      this.solicitacao_id = null;

      this.$refs.PixPyamentOBJ.reset();
      this.emitirNota = false;
      this.data = moment().format("YYYY-MM-DD");
      this.vl_desconto = 0;
      this.vl_desconto1 = 0;
      this.vl_desconto2 = 0;
      this.cod_caixa = this.$store.state.caixa_ativo
        ? this.$store.state.caixa_ativo.cod_caixa
        : 1;
    },
    async carregarPedido() {
      this.carregarMotos();

      this.loading = true;
      if (this.pedido) {
        this.CupomOpen = Object.assign({}, { ...this.pedido });
        let ped = Object.assign({}, { ...this.pedido });
        if (ped) {
          this.data = moment(ped.data).format("YYYY-MM-DD");
          this.editando = true;
          this.onpedido = ped.sistema == "ONPEDIDO" || ped.sistema == "IFOOD";
          this.imprimir = this.onpedido;
          this.tipo_entrega = this.opcoes_entrega.find(
            (x) => x && x.titulo && x.titulo == ped.tipo_entrega
          );
          console.log("PED", ped);
          if (ped.dados_pagamento && ped.dados_pagamento.vale) {
            this.pagamento.dados_pagamento = ped.dados_pagamento;
          }

          // console.log("cli", ped.cliente);
          this.cliente = ped.cliente;
          // console.log("cli", ped.cliente);

          this.cliente.status = "ok";

          this.previsao_entrega = ped.tempo_entrega;

          this.vl_subtotal = ped.vl_subtotal;
          this.vl_acrescimo = ped.vl_acrescimo; //.toString().indexOf('R$') >= 0 ? this.formatFloatToReal(ped.vl_acrescimo) : ped.vl_acrescimo;

          this.vl_desconto = this.formatFloatToReal(ped.vl_desconto);
          this.vl_desconto1 = this.formatFloatToReal(ped.vl_desconto);
          this.vl_desconto2 = this.formatFloatToReal(ped.vl_desconto);
          console.log("ped vl", this.vl_desconto1);

          this.dt_agendamento = null;
          this.hr_agendamento = null;

          if (ped.agendamento && ped.agendamento != "") {
            this.dt_agendamento = moment(ped.agendamento).format("YYYY-MM-DD");
            this.hr_agendamento = moment(ped.agendamento).format("HH:mm:ss");
            this.agendamento_obs = ped.agendamento_obs;
          }

          this.cod_moto = ped.cod_moto;

          this.observacoes = ped.observacoes;

          this.empresa_selecionada = this.empresas.find(
            (x) => x.cod_empresa === ped.cod_empresa
          );
          this.vl_entrega_moto = this.formatFloatToReal(ped.vl_entrega_moto);

          if (
            !this.vl_entrega_moto ||
            typeof this.vl_entrega_moto == "undefined"
          ) {
            this.vl_entrega_moto = "R$ 0,00";
          }

          if (this.pedido.comanda_id) {
            this.comandaSelecionada = this.pedido.comanda_id;
          }

          console.log(
            "SETADO BF",
            this.cod_caixa,
            this.$store.state.caixa_ativo.cod_caixa
          );

          if (!this.pedido.pagamento || this.pedido.pagamento == "") {
            this.cod_caixa = this.$store.state.caixa_ativo.cod_caixa;
          } else {
            this.cod_caixa = ped.cod_caixa;
          }
          console.log("SETADO", this.cod_caixa);

          //this.vl_total = ped.vl_total;

          const itens = await Pedido.getItens(ped.cod_pedido);

          if (itens.success && itens.data && itens.data.length > 0) {
            console.log("its", itens);
            if (
              this.pedido.sistema == "ONPEDIDO" ||
              this.pedido.sistema == "IFOOD"
            ) {
              // this.itens = itens.data;
              this.itensPai = itens.data.filter(
                (x) => x.onpedido && !x.cod_item_pai && x.cod_item_pai == null
              );
              this.itensPai.map((it) => {
                it.Filhos = itens.data.filter(
                  (x) => x.cod_item_pai == it.cod_item
                );
              });
            }
            {
              console.log(
                "its no app",
                itens.data.filter((x) => !x.onpedido)
              );
              this.itens = await itens.data
                .filter((x) => !x.onpedido)
                .map((item) => {
                  if (item.itens) {
                    item.itens = item.itens.map((it) => {
                      // console.log(it);
                      let ob = this.$store.state.cardapio.find(
                        (x) =>
                          (x && x.Grupo && it.Grupo && x.Grupo == it.Grupo) ||
                          x.cod_grupo == it.cod_grupo
                      );
                      if (ob && ob.produtos) {
                        ob = ob.produtos.find(
                          (x) => x.cod_produto == it.cod_produto
                        );
                      }
                      return {
                        ...it,
                        ...ob,
                        vl_unitario: it.vl_unitario,
                        vl_total: it.vl_total,
                        preco_venda: it.preco_venda,
                      };
                    });
                    return item;
                  }
                });
            }
          }

          // if (this.onpedido) {
          // }

          this.$forceUpdate();
          let conta = ped.tipo_entrega == "BUSCAR" ? 0 : -1;
          //console.log("CONTA==>", conta);
          this.$nextTick(() => {
            this.forma_pagamento =
              ped.pagamento == "Dinheiro"
                ? conta + 1
                : ped.pagamento == "Cartão"
                ? conta + 2
                : ped.pagamento == "Dim. + Cart."
                ? conta + 3
                : ped.pagamento == "Vale"
                ? conta + 4
                : ped.pagamento == "Online"
                ? conta + 5
                : ped.pagamento == "Só Entregar"
                ? conta + 6
                : ped.pagamento == "PIX"
                ? conta + 7
                : ped.pagamento == "VR"
                ? conta + 8
                : 0;
            //console.log(ped.pagamento, this.forma_pagamento);

            this.$nextTick(() => {
              setTimeout(() => {
                // console.log("set cli", ped.cliente);
                this.cliente = ped.cliente;
                this.pagamento.vl_dinheiro =
                  "R$ " + ped.vl_dinheiro.toFixed(2).replace(".", ",");
                this.pagamento.vl_cartao =
                  "R$ " + ped.vl_cartao.toFixed(2).replace(".", ",");

                // this.vl_entrega = ped.vl_entrega;

                this.$nextTick(() => {
                  setTimeout(() => {
                    this.vl_entrega = this.formatFloatToReal(ped.vl_entrega);
                    this.vl_entrega_moto = this.formatFloatToReal(
                      ped.vl_entrega_moto
                    );
                  }, 200);
                });
              }, 200);
            });
          });

          // //console.log(1111111111, this.itens);
        }
        //console.log("pedidodooo,", this.pedido);
      }

      this.loading = false;
      this.entregaChanged = false;
      this.forceChangeCaixa = false;
    },
    getNumberFromValor(vl) {
      // //console.log(vl, typeof vl);
      return parseFloat(
        vl
          .toString()
          .replace("R$", "")
          .replace(" ", "")
          .replace(".", "")
          .replace(",", ".")
      );
    },
    editar(item) {
      this.item_edit = item;
      console.log(
        "has edit",
        this.$refs && this.$refs.CardapioMdl && this.$refs.CardapioMdl
          ? this.$refs.CardapioMdl
          : this.$refs.CardapioMdl2
      );
      (this.$refs && this.$refs.CardapioMdl && this.$refs.CardapioMdl
        ? this.$refs.CardapioMdl
        : this.$refs.CardapioMdl2
        ? this.$refs.CardapioMdl2
        : this.$refs.CardapioMdl3
      ).editar(item);
    },
    calcularItens() {
      let soma =
        this.$refs &&
        this.$refs.CardapioMdl &&
        this.$refs.CardapioMdl.calcularTotal
          ? this.$refs.CardapioMdl.calcularTotal
          : this.$refs &&
            this.$refs.CardapioMdl2 &&
            this.$refs.CardapioMdl2.calcularTotal
          ? this.$refs.CardapioMdl2.calcularTotal
          : null;
      if (this.itens && this.itens.length > 0) {
        for (let it of this.itens) {
          soma(it);
          // console.log("calculando item", it, res);
          //it.vl_unitario = soma(it);
          //it.vl_total = it.vl_unitario * it.qtd;
        }
      }
    },
    calcularTotal() {
      // console.log("itens", this.itens);

      // //  this.$refs.CardapioMdl.editar(it);
      // //  this.$refs.CardapioMdl.adicionar();

      // }
      //console.log("ittens", this.itens, this.itensPai);
      this.vl_subtotal = 0;
      if (this.itens && this.itens.length > 0) {
        this.vl_subtotal += this.itens.reduce((ret, vl) => {
          ret += vl.vl_total;
          return ret;
        }, 0);
      }
      if (this.onpedido && this.itensPai) {
        //this.vl_subtotal = 0;
        this.vl_subtotal += this.itensPai.reduce((ret, vl) => {
          ret += vl.valor;
          return ret;
        }, 0);
      }
      // this.vl_total = this.vl_subtotal  - ;
    },
    removerProblema() {
      if (this.pedido && this.pedido.pedido_erro) {
        this.pedido.pedido_erro = 0;
        this.pedido.motivo_erro = "";
      }
    },
    async selecionarEmpresa(emp) {
      this.empresa_selecionada = emp;
      await this.$forceUpdate();
      await this.$nextTick();
      this.$nextTick(() => {
        console.log("Empresa", emp);
        this.caixasDisponiveis = emp.caixas;
        this.verificarAberturaCaixa();
        if (emp && emp.configuracoes && emp.configuracoes.frete_padrao) {
          if (!this.entregaChanged) {
            this.vl_entrega_padrao = this.formatFloatToReal(
              +emp.configuracoes.frete_padrao
            );
            this.vl_entrega =
              this.tipo_entrega && this.tipo_entrega.titulo
                ? this.formatFloatToReal(
                    +(this.tipo_entrega.titulo == "ENTREGA"
                      ? emp.configuracoes.frete_padrao
                      : 0)
                  )
                : 0;
            if (
              this.tipo_entrega &&
              this.tipo_entrega.titulo == "ENTREGA" &&
              emp.cod_empresa == 1
            ) {
              this.cod_caixa = 4;
            } else {
              this.cod_caixa = 1;
            }
            this.calcularItens();
            this.calcularTotal();
          }
        }
      });
    },
    escolherTipoEntrega(tipo) {
      this.passo = 1;
      this.tipo_entrega = tipo;
      if (
        !this.$store.state.isMobile &&
        (!this.cliente || !this.cliente.Nome)
      ) {
        this.$refs.ClienteForm.$refs.txtTel.focus();
        this.$refs.ClienteForm.$refs.txtTel.select();
      }
    },
    removerItem(item) {
      const it = this.itens.find(
        (x) =>
          x.itens.find((c) => c.cod_produto === item.itens[0].cod_produto) !=
          null
      );

      this.listaRemoveItem.push(it);
      this.itens = this.itens.filter((x) => x != it);
      this.calcularTotal();
      // console.log("remov", this.listaRemoveItem);
    },
    addPrevisao(tempo) {
      this.previsao_entrega = moment().add(tempo, "m").format("HH:mm");
    },
    cancelar() {
      //if (this.mesa && this.mesa.id > 0) {
      this.$emit("close");
      this.$emit("atualiza");
      //}
    },
    confirmaEmpresa() {
      this.empresaConfirmada = true;
      // console.log("EMPRESA CONFIRMADA", this.empresaConfirmada);
      this.salvar();
    },
    solicitarLoginAdm() {
      if (!this.authAdm || this.authAdm == false) {
        this.loginAdm = true;
        return false;
      } else {
        return true;
      }
    },
    async cancelarIfood() {
      console.log("cancelamento", this.razaoCancelamento.selecionado);
      if (this.razaoCancelamento && this.razaoCancelamento.selecionado) {
        let result = (
          await Pedido.cancelarPedido(
            this.pedido,
            this.razaoCancelamento.selecionado
          )
        ).data;
        console.log("cancelando", result);
        if (result.success) {
          this.$emit("close");
          this.$emit("atualiza");
          if (this.$props.CloseOnSave) {
            this.$props.CloseOnSave();
          }
        } else {
          alert("erro ao cancelar");
        }
      } else {
        this.$swal(
          "Atenção!",
          "Selecione um motivo para cancelamento do pedido!",
          "error"
        );
      }
    },
    async cancelarPedido() {
      console.log("ped nivel", this.user.nivel);

      if (this.pedido && this.solicitarLoginAdm() === true) {
        //   if (this.user.nivel > 1) {
        //   this.$swal(
        //     "Atenção!",
        //     "Você não tem permissão para cancelar pedidos!",
        //     "error"
        //   );
        //   return;
        // }
        await this.$swal({
          title: "Confirmação",
          text: "Você tem certeza que deseja cancelar este pedido?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Sim! Cancelar.",
          cancelButtonText: "Não",
        }).then(async (rep) => {
          if (rep.isConfirmed) {
            if (this.pedido.sistema == "IFOOD") {
              await this.carregarRazaoCancelamento();
              return;
            } else {
              let result = (await Pedido.cancelarPedido(this.pedido)).data;
              if (result.success) {
                this.$emit("close");
                this.$emit("atualiza");
                if (this.$props.CloseOnSave) {
                  this.$props.CloseOnSave();
                }
              } else {
                alert("erro ao cancelar");
              }
            }
          }
        });
      }
    },
    async saveAndPrint(tipo) {
      await this.salvar(tipo);
    },
    async salvar(solicPrint) {
      // //console.log(
      //   this.$parent.$refs.conteudo,
      //   this.$parent.$refs.conteudo.scrollTop
      // );
      // if (this.$parent.$refs.conteudo) {
      //   this.$parent.$refs.conteudo.scrollTop = 0;
      //   this.$forceUpdate();
      //   await this.$nextTick();
      // }
      // console.log(1);

      if (this.dt_agendamento && !this.hr_agendamento) {
        this.$swal("Atenção!", "Informe a HORA do agendamento!", "error");
        return;
      }

      if (!this.dt_agendamento && this.hr_agendamento) {
        this.$swal("Atenção!", "Informe a DATA do agendamento!", "error");
        return;
      }

      if (
        this.dt_agendamento &&
        this.hr_agendamento &&
        this.cliente &&
        (!["ok", "not"].includes(this.cliente.status) ||
          validador.string.isNullOrEmpty(this.cliente.Nome) ||
          validador.string.isNullOrEmpty(this.cliente.Telefone))
      ) {
        this.$swal(
          "Atenção!",
          "Para realizar pedido agendado é necessário informar o cliente!",
          "error"
        );
        return;
      }
      // console.log("empresas", this.empresas)

      // console.log(this.itens);
      let erros = [];

      if (
        this.itens.length <= 0 &&
        (!this.itensPai || this.itensPai.length <= 0)
      ) {
        erros.push("Insira ao menos 1 item para concluir o pedido!");
      } else {
        this.salvando = true;
        try {
          for (let it of this.itens) {
            if (it.itens && it.itens.length > 0) {
              for (let itt of it.itens) {
                // console.log("itt", itt);
                //let fndDisp = await ProdutoLib.verificarDisponibilidade(itt.cod_produto, this.empresa_selecionada.cod_empresa);
                if (itt.status && itt.status.length > 0) {
                  let inativos = itt.status.find(
                    (x) =>
                      x.cod_empresa === this.empresa_selecionada.cod_empresa &&
                      x.status === 0
                  );
                  // console.log("inatis", inativos);
                  if (inativos) {
                    erros.push(
                      "O item " +
                        itt.nome +
                        " está indisponível no local " +
                        inativos.empresa
                    );
                  }
                }
              }
            }
          }
        } catch (errr) {
          console.log("ERRO ON FND DISP", errr);
        }
        this.salvando = false;
      }
      //let soma = this.tipo_entrega.titulo == "BUSCAR" ? 0 : 0;
      let somaa =
        this.tipo_entrega &&
        this.tipo_entrega.titulo &&
        this.tipo_entrega.titulo == "BUSCAR"
          ? 1
          : 0;

      if (
        this.forma_pagamento - somaa == 6 &&
        (!this.pagamento.dados_pagamento ||
          !this.pagamento.dados_pagamento.PIX ||
          this.pagamento.dados_pagamento.PIX.valor != this.vl_total ||
          this.pagamento.dados_pagamento.PIX.status != "PAGO")
      ) {
        // console.log(
        //   "PAGAMENTO",
        //   this.forma_pagamento,
        //   this.pagamento,
        //   this.vl_total
        // );
        erros.push(
          "PIX não PAGO / CONCLUIDO ou valor pago divergente do total do pedido!"
        );
      }
      if (
        !["Administrador", "Gerente"].includes(this.user.tipo) &&
        [3 + somaa, 4 + somaa, 5 + somaa].includes(this.forma_pagamento) &&
        (!this.authAdm || this.authAdm == false)
      ) {
        erros.push(
          "A forma de pagamento escolhida é obrigatório permissão do gerente para ser utilizado!"
        );
        this.solicitarLoginAdm();
      }
      if (
        this.tipo_entrega &&
        this.tipo_entrega.titulo &&
        this.tipo_entrega.titulo != "BUSCAR"
      ) {
        // let conta = this.tipo_entrega.titulo == "BUSCAR" ? 0 : -1;
        this.vl_total = parseFloat(this.vl_total).toFixed(2);
        console.log(
          "vl",
          parseFloat(parseFloat(this.vl_total).toFixed(2)),
          parseFloat(
            (
              this.getNumberFromValor(this.pagamento.vl_dinheiro) +
              this.getNumberFromValor(this.pagamento.vl_cartao)
            ).toFixed(2)
          )
        );
        if (
          (this.forma_pagamento == 0 &&
            parseFloat(
              parseFloat(
                this.getNumberFromValor(this.pagamento.vl_dinheiro)
              ).toFixed(2)
            ) < parseFloat(parseFloat(this.vl_total)).toFixed(2)) ||
          (this.forma_pagamento == 2 &&
            parseFloat(
              (
                this.getNumberFromValor(this.pagamento.vl_dinheiro) +
                this.getNumberFromValor(this.pagamento.vl_cartao)
              ).toFixed(2)
            ) < parseFloat(parseFloat(this.vl_total).toFixed(2))) ||
          (this.forma_pagamento == 1 &&
            parseFloat(
              parseFloat(
                this.getNumberFromValor(this.pagamento.vl_cartao).toString()
              ).toFixed(2)
            ) < parseFloat(parseFloat(this.vl_total.toString()).toFixed(2)))
        ) {
          erros.push(
            "O valor informado no pagamento é menor que o valor do pedido"
          );
        }
        if (
          (this.forma_pagamento == 1 || this.forma_pagamento == 2) &&
          parseFloat(
            parseFloat(
              this.getNumberFromValor(this.pagamento.vl_cartao)
            ).toFixed(2)
          ) > parseFloat(parseFloat(this.vl_total).toFixed(2))
        ) {
          erros.push(
            "O valor informado no cartão é maior que o valor total do pedido."
          );
        }
        this.pagamento.vl_troco = this.vl_troco;
      }
      if (
        !this.pedido &&
        this.pedido == null &&
        typeof this.pedido == "undefined"
      ) {
        //console.log("som", soma);
        if (this.forma_pagamento == 1) {
          this.pagamento.vl_cartao = 0;
        } else if (this.forma_pagamento == 2) {
          this.pagamento.vl_dinheiro = 0;
        }
      }
      // if (this.forma_pagamento == soma + 3) {
      //   //console.log(
      //     "dim + cart",
      //     this.vl_total,
      //     this.pagamento.vl_cartao,
      //     this.pagamento.vl_dinheiro,
      //     this.getNumberFromValor(this.pagamento.vl_cartao)
      //   );
      // }

      // //console.log("cliente", this.cliente);
      if (
        !this.mesa &&
        (!this.tipo_entrega ||
          !this.tipo_entrega.titulo ||
          this.tipo_entrega.titulo == "")
      ) {
        erros.push("Selecione um tipo de entrega!");
      }
      if (
        this.tipo_entrega &&
        this.tipo_entrega.titulo &&
        (this.tipo_entrega.titulo == "ENTREGA" ||
          this.tipo_entrega.titulo == "BUSCAR") &&
        (!this.cliente ||
          (this.cliente &&
            (!["ok", "not"].includes(this.cliente.status) ||
              validador.string.isNullOrEmpty(this.cliente.Nome) ||
              validador.string.isNullOrEmpty(this.cliente.Telefone))))
      ) {
        erros.push("Você precisa informar um cliente!");
        if (
          this.tipo_entrega &&
          this.tipo_entrega.titulo &&
          this.tipo_entrega.titulo == "ENTREGA" &&
          (!this.cliente.Logradouro ||
            !this.cliente.Numero ||
            !this.cliente.Bairro)
        ) {
          erros.push("Informe o endereço completo do cliente!");
        }
      }

      if (this.pedido && this.pedido.cod_pedido) {
        let res = await this.$swal
          .fire({
            title: "ATUALIZANDO PEDIDO",
            icon: "warning",
            timer: 9000,
            allowEscapeKey: true,
            html: "Você está ATUALIZANDO um pedido! <br/><br/><b>Deseja continuar?</b>",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "ATUALIZAR!",
            cancelButtonText: "Cancelar",
          })
          .then((r) => r.isConfirmed);

        if (!res) {
          this.salvando = false;
          return;
        }
      }

      if (erros.length > 0) {
        this.$swal
          .fire({
            title: "Atenção",
            icon: "error",
            timer: 9000,
            allowEscapeKey: true,
            html: erros.reduce((v, it) => {
              v += `${it} <br />`;
              return v;
            }, ""),
          })
          .then(() => {
            this.salvando = false;
            // if (this.$parent.$refs.conteudo) {
            //   setTimeout(() => {
            //     this.$parent.$refs.conteudo.scrollTop = 0;
            //     this.$parent.$refs.conteudo.scrollTo = 0;
            //   }, 550);
            // }
          });
      } else {
        if (
          !this.mesa &&
          (!this.pedido ||
            (this.pedido == null && typeof this.pedido == "undefined")) &&
          !this.empresaConfirmada &&
          //this.cod_caixa == 4
          ["ENTREGA", "BUSCAR"].includes(this.tipo_entrega.titulo) &&
          (!this.empresas || this.empresas.length > 1)
        ) {
          // console.log(
          //   "necessario confirmar empresa",
          //   this.pedido,
          //   this.empresaConfirmada
          // );
          this.$bvModal.show("modal-verificar-empresa");
          this.salvando = false;
          return;
        }

        this.salvando = true;

        setTimeout(() => {
          this.salvando = false;
        }, 40000);

        let data = {
          sistema: "INTERNO-WEB",
          imprimir: this.imprimir,
          forma_pagamento: this.forma_pagamento,
          cliente: this.cliente,
          tipo_entrega:
            this.mesa && this.mesa.id > 0
              ? { titulo: "MESA" }
              : this.tipo_entrega,
          itens: this.itens,
          vl_subtotal: this.vl_subtotal,
          vl_entrega: this.formatValorReal(this.vl_entrega),
          vl_entrega_moto: this.formatValorReal(this.vl_entrega_moto),
          vl_desconto: this.vl_desconto,
          vl_total: this.vl_total,
          previsao_entrega: this.previsao_entrega,
          id: this.id,
          pagamento: this.pagamento,
          empresa: this.empresa_selecionada,
          cod_caixa: this.cod_caixa,
          onpedido: this.onpedido,
          observacoes: this.observacoes,
          alerta: this.alerta,
          listaRemoveItem: this.listaRemoveItem,
          mesa: this.mesa,
          comanda: this.comandaSelecionada,
          solicitacao_id: this.solicitacao_id,
          forceChangeCaixa: this.forceChangeCaixa,
          data: moment(this.data).format(
            "YYYY-MM-DD " +
              (this.pedido && this.pedido.data
                ? moment(this.pedido.data).format("HH:mm:ss")
                : moment().format("HH:mm:ss"))
          ),
          agendamento:
            this.dt_agendamento && this.dt_agendamento != ""
              ? moment(this.dt_agendamento).format(
                  "YYYY-MM-DD " +
                    moment(
                      moment().format("YYYY-MM-DD " + this.hr_agendamento)
                    ).format("HH:mm:ss")
                )
              : "",
          agendamento_obs: this.agendamento_obs,
          pedido_erro: this.pedido ? this.pedido.pedido_erro : 0,
          motivo_erro: this.pedido ? this.pedido.motivo_erro : "",
        };
        if (
          this.pedido &&
          this.pedido != null &&
          typeof this.pedido != "undefined"
        ) {
          data.sistema = this.pedido.sistema;
          data.cod_pedido = this.pedido.cod_pedido;
          data.senha = this.pedido.senha;
          data.status = this.pedido.status;
          if (!data.data) {
            data.data = moment(this.pedido.data).format("YYYY-MM-DD HH:mm:ss");
          }
          data.cod_moto = this.cod_moto;
        }

        data.cod_caixa =
          this.mesa && this.mesa.id > 0 && this.mesa.cod_caixa_mesa
            ? this.mesa.cod_caixa_mesa
            : this.cod_caixa;
        if (this.editando) {
          data.cod_caixa =
            this.cod_caixa || this.$store.state.caixa_ativo.cod_caixa;
        }

        //console.log("salvando", this.cod_caixa, data, this.pedido);
        // for (let i = 0; i <script 5; i++) {
        //   Pedido.store(data);
        // }
        console.log("sending data", data);
        const res = await Pedido.store(data);
        // console.log(res.data);
        if (res && res.success) {
          await this.$swal
            .fire({
              title: "Concluído",
              allowEscapeKey: true,
              text: "Pedido salvo com sucesso! Senha: " + res.data.senha,
              icon: "success",
            })
            .then(async () => {
              console.log("OK");

              if (solicPrint) {
                await (this.$refs.printButtons
                  ? this.$refs.printButtons
                  : this.$refs.printButtons2
                ).solicitar(solicPrint, true);
              }

              if (this.mesa && this.mesa.id > 0) {
                this.$emit("close");
                this.$emit("atualiza");
              }

              //await this.$store.dispatch("auth/atualizarCardapio");
              if (this.emitirNota === true) {
                this.CupomOpen = { ...res.data };
                this.$nextTick(() => {
                  this.showCupom();
                });
              }
              this.$forceUpdate();
              if (this.$props.CloseOnSave) {
                this.$emit("close");
                this.$props.CloseOnSave();
              }
              if (this.$parent.$refs.conteudo) {
                this.$parent.$refs.conteudo.scrollTop = 0;
                this.$forceUpdate();
                await this.$nextTick();
              }

              this.cliente = { status: "none", Nome: "", Telefone: "" };
              this.$forceUpdate();
              this.reset();

              this.$refs.ClienteForm.clearCliente();
              this.$forceUpdate();
              this.salvando = false;

              if (this.$parent.$refs.conteudo) {
                setTimeout(() => {
                  this.$parent.$refs.conteudo.scrollTop = 0;
                  this.$parent.$refs.conteudo.scrollTo = 0;
                }, 550);
              }

              this.salvando = false;
            });
        } else {
          this.$swal.fire({
            title: "Atenção",
            allowEscapeKey: true,
            text: "Erro ao salvar pedido: " + res.message,
            icon: "error",
          });
          //this.salvando = false;
        }
        // //console.log(res);

        if (this.$parent.$refs.conteudo) {
          setTimeout(() => {
            this.$parent.$refs.conteudo.scrollTop = 0;
            this.$parent.$refs.conteudo.scrollTo = 0;
          }, 550);
        }
        if (this.$parent.$refs.conteudo) {
          this.$parent.$refs.conteudo.scrollTop = 0;
          this.$parent.$refs.conteudo.scrollTo = 0;
          this.$forceUpdate();
          await this.$nextTick();
        }
      }
    },
    async descancelar() {
      await this.$swal({
        title: "Confirmação",
        text: "Você tem certeza que deseja remover a marcação cancelado deste pedido?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (rep) => {
        if (rep.isConfirmed) {
          let result = (await Pedido.descancelarPedido(this.pedido)).data;
          if (result.success) {
            this.$emit("close");
            this.$emit("atualiza");
            if (this.$props.CloseOnSave) {
              this.$props.CloseOnSave();
            }
          } else {
            alert("erro ao descancelar");
          }
        }
      });
    },
    verLog() {
      console.log("openlog");
      this.$bvModal.show("modal-log");
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/assets/scss/pedido.scss"
</style>
